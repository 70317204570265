import { Col, Row } from "antd";
import React from "react";
import "./PrivacyPolicy.css";
function PrivacyPolicy() {
  return (
    <div className="privacyPolicyMain">
      <Row justify="center">
        <Col xs={21} sm={21} md={21} lg={19} xl={19}>
          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="privacyHeading">Privacy Policy</div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="privacyPara">
                Cubes and Dots ("C&D", "we", "us") values its customers'
                privacy. We are committed to establishing and maintaining
                policies and procedures that protect the privacy and personal
                information gathered from our clients, prospects, and other
                website visitors.
              </p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="privacySubHeading">Information Requested</span>
              <p className="privacyPara">
                Depending on the type of transaction you enter, we may collect
                any or all of the information you provide us, including your
                name, address, telephone number, and email address, as well as
                data about your use of the website. Other information that may
                be required to process a request from time to time may also be
                collected as indicated on the website.
              </p>

              <span className="privacySubHeading">Opt Out</span>
              <p className="privacyPara">
                Visitors who request information may sign up to receive future
                Cubes and Dots e-mails. Those who sign up for, and those who are
                already receiving e-mail from Cubes and Dots, will always have
                the option to opt out of receiving messages, which will be
                included at the bottom of each e-mail.
              </p>

              <span className="privacySubHeading">Cookies</span>
              <p className="privacyPara">
                Your Internet browser includes the capability of storing small
                files called "cookies" that contain information that allows a
                website to recognise your account. This feature is used on our
                website to improve your experience. You have the option to
                prevent your computer from accepting cookies, but doing so may
                impair certain website functionality.
              </p>

              <span className="privacySubHeading">
                Disclosure of Information
              </span>
              <p className="privacyPara">
                We do not sell, trade, or otherwise transfer your personally
                identifiable information to third parties. This does not include
                trusted third parties who help us operate our website, conduct
                our business, or provide you with services, as long as those
                parties agree to keep this information confidential. We may also
                disclose your information if we believe it is necessary to
                comply with the law, enforce our site policies, or protect our
                or others' rights, property, or safety. Non-personally
                identifiable visitor information, on the other hand, may be
                shared with third parties for marketing, advertising, or other
                purposes.
              </p>

              <span className="privacySubHeading">Security</span>
              <p className="privacyPara">
                We utilize industry-standard encryption technologies when
                transferring and receiving user data exchanged with our site. We
                also have adequate security measures at our physical facilities
                to protect and retain information collected from you at our
                site.
              </p>

              <span className="privacySubHeading">Consent </span>
              <p className="privacyPara">
                By using our site, you consent to this privacy policy.
              </p>

              <span className="privacySubHeading">
                Changes to Privacy Policy{" "}
              </span>
              <p className="privacyPara">
                If we decide to change our privacy policy, we will post those
                changes on this page.
              </p>

              <span className="privacySubHeading">Questions</span>
              <p className="privacyPara">
                If you have any questions or complaints relating to this Privacy
                Policy or how we use the personal information we have about you,
                please contact us at <a className="link" href="mailto:hello@cubesanddots.com"><span className="privacyEmail">info@cubesanddots.com</span></a>
              </p>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <div className="privacyCopyRights">Copyright © 2022 Cubes&Dots All rights reserved.</div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default PrivacyPolicy;
