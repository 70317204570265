import React, { useEffect, useState } from "react";
import "./Screen2.css";
import fantasyLogo from "../../Images/fantasyLogo.svg";
import cubicLogo from "../../Images/cubicLogo.png";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { CgMore } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { setNavLogo } from "../../Redux/actions/NavRefAction";
import vertical from "../../Images/cubicVertical.svg";
import verticalRed from "../../Images/cubicVertical_red.svg";
import square from "../../Images/cubicLogo.png";
import dayfresh from "../../Images/Logos/dayFresh.svg";
import bays from "../../Images/Logos/bays.svg";
import blueex from "../../Images/Logos/blueex.svg";
import brandedge from "../../Images/Logos/brandedge.svg";
import changan from "../../Images/Logos/changan.svg";
import cinepex from "../../Images/Logos/cinepex.svg";
import dsm from "../../Images/Logos/dsm.svg";
import forty4 from "../../Images/Logos/forty4.svg";
import globalLinkx from "../../Images/Logos/globalLinkx.svg";
import hsfDesigns from "../../Images/Logos/hsfDesigns.svg";
import mc from "../../Images/Logos/mc.svg";
import pentaloons from "../../Images/Logos/pantaloons.svg";
import rockSmith from "../../Images/Logos/rockSmith.svg";
import safarNama from "../../Images/Logos/safarNama.svg";
import shaheen from "../../Images/Logos/shaheen.svg";
import tenaDurrani from "../../Images/Logos/tenaDurrani.svg";
import unilever from "../../Images/Logos/unilever.svg";
import cibex from "../../Images/Logos/cibex.svg";

function Screen2({ navRef }) {
  const companyLogo = [
    { logo: dayfresh },
    { logo: bays },
    // { logo: tenaDurrani },
    { logo: blueex },
    { logo: brandedge },
    // { logo: pentaloons },
    { logo: changan },
    { logo: cinepex },
    { logo: dsm },
    { logo: forty4 },
    { logo: globalLinkx },
    { logo: hsfDesigns },
    { logo: mc },
    { logo: rockSmith },
    { logo: safarNama },
    { logo: shaheen },
    { logo: unilever },
    { logo: cibex },
  ];
  const [hover, setHover] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);

        if (entry.isIntersecting) {
          //do your actions here
          dispatch(setNavLogo(`${verticalRed}`));
          console.log("found Div for nav!", navRef.current);
        } else {
          // navRef.current = vertical;
          dispatch(setNavLogo(`${vertical}`));
          console.log("remove!", navRef.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",

        threshold: 0.1,
      }
    );
    if (navRef.current) {
      observer.observe(navRef.current);
    }
  }, [navRef]);
  return (
    <div className="screen2Main">
      <Row>
        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="screen2ContentDiv">
            <div className="subContentDiv">
              <h2 className="mainHeadings">
                <span className="title">
                  We provide our
                  <br />
                  clients with
                  <br />
                  innovative solutions
                  <br />
                </span>
              </h2>
              <div className="mainDescription">
                For transforming their business concepts into &nbsp;
                {/* <br /> */} 
                 immersive experiences for their target audiences. &nbsp;
              </div>
              <div className="screen2Btn">
                <Link to="about_us">
                  <Button type="primary" className="Btn">
                    Our Company
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
          <div className="screen2ContentDiv2">
            <div className="contentImageParent">
              <Row
                style={{ backgroundColor: "", width: "100%" }}
                gutter={[16, 16]}
                justify="center"
              >
                {companyLogo.map((data, index) => (
                  <Col xs={4} sm={8} md={8} lg={8} xl={8} xxl={6}>
                    <div className="companyLogoDiv" key={index}>
                      <img
                        src={data.logo}
                        alt="companyLogo"
                        height="100%"
                        width="100%"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
              {/* <div className="contentImage2">
                <img src={cubicLogo} height="100%" width="100%" alt="Logo" />
              </div> */}
              {/* <div className="cube">
              <div className="side top"></div>
              <div className="side left"></div>
              <div className="side front"></div>
              </div> */}
            </div>
            {/* <Row style={{ backgroundColor: "pink", width: "100%" }}>
              <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                <div className="screen2heading">OUR DNA</div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="dnaCol">
                <div className="dnaNamesDiv">
                  <div className="dnaLogo">
                    logo
                  </div>
                  <div>
                    Creativity
                  </div>
                </div>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <div className="dnaNamesDiv">Creativity</div>
              </Col>
            </Row> */}

            {/* ---------For Responsive Content--------- */}
            <div className="responsiveContent" ref={navRef}>
              <div className="subContentDiv">
                <h2 className="mainHeadings">
                  <div className="title">
                    We provide our
                    <br />
                    partners with innovative
                    <br />
                    solutions
                    <br />
                  </div>
                </h2>
                <div className="mainDescription subTitle">
                  For transforming their business concepts into
                  <br />
                  immersive experiences for their target audiences. &nbsp;
                </div>
                <div className="screen2Btn">
                  <Link to="about_us">
                    <Button type="primary" className="Btn">
                      Our Company
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Screen2;
