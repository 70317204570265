import {
  AutoComplete,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import React, { useState } from "react";
import "./Career.css";
import careerImage from "../../Images/career.jpg";
import Navbar from "../NavBar/Navbar";
import BioDataList from "../../SubComponents/BioDataList/BioDataList";
import { SiExpertsexchange } from "react-icons/si";
// import { Link } from "react-router-dom";
// import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
import TextArea from "antd/lib/input/TextArea";
import InputMask from "../../SubComponents/Input-Mask";
import { CgAsterisk } from "react-icons/cg";
import { CustomerService } from "../../Services/CustomerService";

const rules = {
  full_name: [
    {
      required: true,
      //   message: "Please input your company name",
    },
  ],
  phone: [
    {
      required: true,
    },
    {
      // pattern: new RegExp(/^[\+]?[0-9]{2}[\s][0-9+]{3}-[0-9+]{7}$/),
      // message: "Please enter valid number (eg. 315-0000000)",
      // required: true,
    },
  ],
  cnic: [
    {
      required: true,
    },
    {
      pattern: new RegExp(/^[0-9+]{5}-[0-9+]{7}-[0-9]{1}$/),
      message: "Please enter valid cnic (eg. 42011-9472856-1)",
      required: false,
    },
  ],
  availability: [
    {
      required: true,
      message: "Enter your availability",
    },
  ],
  city: [
    {
      required: true,
    },
  ],
  salary: [
    {
      required: true,
    },
    {
      pattern: new RegExp(/^[0-9]+$/),
      message: "Please enter amount in number",
    },
  ],
  expected_salary: [
    {
      required: true,
    },
    {
      pattern: new RegExp(/^[0-9]+$/),
      message: "Please enter amount in number",
    },
  ],
  email: [
    {
      required: true,
      type: "email",
    },
  ],
};

function Career() {
  const { Option } = Select;
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const [value, setValue] = useState(1);
  const [duration, setDuration] = useState(0);
  const [days, setDays] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const onChange = (e) => {
    console.log("radio checked", e.target.value);
    setValue(e.target.value);
  };

  const onSubmitForm =async(values)=>{
    values["candidate_joining"] = `After ${duration} ${days}`;
    const formData = new FormData();
    formData.append("candidate_name", values.candidate_name);
    formData.append("candidate_email", values.candidate_email);
    formData.append("candidate_phone", values.candidate_phone);
    formData.append("candidate_cnic", values.candidate_cnic);
    formData.append("candidate_city", values.candidate_city);
    formData.append("candidate_joining", `After ${duration} ${days}`);
    formData.append("candidate_salary", values.candidate_salary);
    formData.append("candidate_expected_salary", values.candidate_expected_salary);
    formData.append("candidate_file", selectedFile);
    var data = await new CustomerService().sendCandidateData(formData);
    setLoading(true);
    if(data.data.success === true){
      message.success(data.data.message);
    form.resetFields();
    setLoading(false);
    }
    if(data.data.success === false){
      message.info(data.data.message);
      setLoading(false);
    }
    console.log("Data===", data);
  }

  const onChangeDuration=(value)=>{
    // console.log("Duration===", value);
    setDuration(value)
  }

  const onChangeDays=(value)=>{
    // console.log("Days===", value);
    setDays(value)
  }

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  // const maxAllowedSize = 5 * 1024 * 1024;
  // if (target.files[0].size > maxAllowedSize) {
  //   // Here you can ask your users to load correct file
  //    target.value = ''
  // }
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className="careerDiv"
    >
      <div className="careerHeadingsDiv">
        <Row justify="center">
          <Col xs={24} sm={24} md={13} lg={13} xl={13}>
            <h2 className="careerMainHeading mainHeadings">
              <span>
                Can't Find Your <br /> Dream Position?
              </span>
            </h2>
            <div className="careerSubHeading mainDescription">
              <span>
                Apply at the given form and we will see that <br />
                if there is a vacancy that suits your skills
              </span>
            </div>
          </Col>
        </Row>
      </div>
      <div className="careerFormDiv">
        <Form
          {...layout}
          name="contact-info"
          onFinish={onSubmitForm}
          form={form}
          layout="vertical"
        >
          <Row justify="center" gutter={[48]}>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="candidate_name"
                // label="Full Name"
                rules={rules.full_name}
                labelAlign="left"
                className="contactField"
              >
                <Input
                  placeholder={`Full Name*`}
                  // bordered={false}
                />
              </Form.Item>
            </Col>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="candidate_email"
                // label="Email"
                rules={rules.email}
                labelAlign="left"
                className="contactField"
              >
                <Input
                  placeholder="Email*"
                  // bordered={false}
                />
              </Form.Item>
            </Col>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="candidate_phone"
                // label="Contact#"
                rules={rules.phone}
                labelAlign="left"
                className="contactField"
              >
                <InputMask
                  mask="+\929999999999"
                  placeholder="Mobile No.*"
                  // bordered={false}
                />
              </Form.Item>
            </Col>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="candidate_cnic"
                rules={rules.cnic}
                labelAlign="left"
                className="contactField"
              >
                <InputMask
                  mask="99999-9999999-9"
                  placeholder="CNIC*"
                  // bordered={false}
                />
              </Form.Item>
            </Col>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="candidate_city"
                // label="Email"
                labelAlign="left"
                className="contactField"
                rules={rules.city}
              >
                <Select
                  placeholder="Current City*"
                  // bordered={false}
                  className="careerSelect1"
                >
                  <Option value="Karachi">Karachi</Option>
                  <Option value="Lahore">Lahore</Option>
                  <Option value="Islamabad">Islamabad</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                // name="availability"
                // label="Address"
                // rules={rules.availability}
                labelAlign="left"
                className="contactField"
              >
                <Input.Group compact className="careerInput">
                  <InputNumber
                    style={{
                      width: "60%",
                      borderTopRightRadius: "unset",
                      borderBottomRightRadius: "unset",
                      borderBottomLeftRadius: "8px",
                      borderTopLeftRadius: "8px",
                      paddingTop:"5px",
                      paddingBottom:"5px"
                    }}
                    placeholder={"When available to join?*"}
                    onChange={onChangeDuration}
                  />
                  <Select
                    style={{
                      width: "40%",
                    }}
                    className="careerSelect"
                    placeholder="eg. Days"
                    onChange={onChangeDays}
                  >
                    <Option value="Days">Days</Option>
                    <Option value="Week">Week</Option>
                    <Option value="Month">Month</Option>
                  </Select>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="candidate_salary"
                // label="Social Media"
                rules={rules.salary}
                labelAlign="left"
                className="contactField"
              >
                <Input
                  placeholder="Your Current Salary*"
                  // bordered={false}
                />
              </Form.Item>
            </Col>
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="candidate_expected_salary"
                rules={rules.salary}
                labelAlign="left"
                className="contactField"
              >
                <Input
                  placeholder="Your Expected Salary*"
                  // bordered={false}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="expected_salary"
                // label="Email"
                labelAlign="left"
                className="contactField"
                rules={rules.expected_salary}
              >
                <Select 
                  placeholder="Your Expected Salary*" 
                  // bordered={false}
                >
                  <Option value="$1000">$1000</Option>
                  <Option value="$3000">$3000</Option>
                  <Option value="$5000 +">$5000 +</Option>
                </Select>
              </Form.Item>
            </Col> */}
            {/* <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                name="reference"
                // label="Email"
                labelAlign="left"
                className="contactField"
                rules={rules.reference}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "1rem",
                  }}
                >
                  Do you know anyone at Cubes&Dots?{" "}
                  <span
                    style={{
                      color: "red",
                      fontWeight: "400",
                      fontSize: "10px",
                    }}
                  >
                    <i>(Required)</i>
                  </span>
                  <br />
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={1}>A</Radio>
                    <Radio value={2}>B</Radio>
                  </Radio.Group>
                </div>
              </Form.Item>
            </Col> */}
            <Col xs={22} sm={11} md={9} lg={9} xl={9}>
              <Form.Item
                // name="candidate_file"
                // label="Email"
                labelAlign="left"
                className="contactField"
                rules={rules.reference}
              >
                <div
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    marginLeft: "1rem",
                  }}
                >
                  Your Resume
                  <span
                    style={{
                      color: "red",
                      fontWeight: "400",
                      fontSize: "10px",
                    }}
                  >
                    <i>(Required)</i>
                  </span>
                  <br />
                  <input type="file" accept=".pdf" onChange={onImageChange}/>
                  <span style={{ color: "grey", fontSize: "10px" }}>
                    Accepted file types: pdf, Max. file size: 5 MB.
                  </span>
                </div>
              </Form.Item>
            </Col>
            <Col
              xs={23}
              sm={23}
              md={22}
              lg={22}
              xl={22}
              style={{
                display: "flex",
                // justifyContent: "space-around",
              }}
            >
              <div className="careerFormBtn">
                <Button htmlType="submit" className="btn" loading={loading}>
                  Submit
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </motion.div>
  );
}

export default Career;
