import { Avatar, Button, Col, Divider, Form, Input, message, Row, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
// import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import "./Footer.css";
import Navbar from "../NavBar/Navbar";
import InputMask from "../../SubComponents/Input-Mask";
import TextArea from "antd/lib/input/TextArea";
import vertical from "../../Images/cubicVertical.svg";
import verticalRed from "../../Images/cubicVertical_red.png";
import { useDispatch } from "react-redux";
import { setMenuColor, setNavLogo } from "../../Redux/actions/NavRefAction";
import navroz from "../../Images/Navroz.jpeg";
import faiza from "../../Images/Faiza.jpeg";
import sohaib from "../../Images/Sohaib.webp";
import ahsan from "../../Images/Ahsan.webp";
import emailjs from "@emailjs/browser";
import { Link } from "react-router-dom";
import { CustomerService } from "../../Services/CustomerService";
import ErrorList from "antd/lib/form/ErrorList";

function Footers({navRef}) {
  const dispatch = useDispatch();
  const navMenu = useRef("black");
  const navRef2 = useRef();
  // const address = [
  //   {
  //     id: 1,
  //     name: "Lahore",
  //     directions: "Get Directions",
  //     num2: "+923245768469",
  //   },
  //   {
  //     id: 1,
  //     name: "Karachi",
  //     directions: "Get Directions",
  //     num2: "+923245768469",
  //   },
  //   { id: 1, name: "Islamabad", directions: "Get Directions", num2: "" },
  // ];

  const people = [
    {
      id: 1,
      name: "Navroz Mansiya",
      designation: "Founder & CEO",
      img: navroz,
    },
    {
      id: 2,
      name: "Faiza Barkat",
      designation: "COO & Co-Founder",
      img: faiza,
    },
    {
      id: 3,
      name: "Sohaib Manzoor",
      designation: "CMO & Co-Founder",
      img: sohaib,
    },
    {
      id: 4,
      name: "Ahsan Khan",
      designation: "CBDO & Co-Founder",
      img: ahsan,
    },
  ];

  const formMail = useRef();
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const [loading, setLoading] = useState(false);

  const onSubmitForm = async (values) => {
    // console.log("Values===", values);
    setLoading(true);
    var data = await new CustomerService().sendCustomerData(values);
    if (data.data.success === true) {
      message.success(data.data.message);
      form.resetFields();
      setLoading(false);
    }
    if (data.data.success === false) {
      message.info(data.data.message);
      setLoading(false);
    }
    // console.log("Data===", data);
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          //do your actions here
          var nav = (navMenu.current = "white");
          dispatch(setMenuColor(nav));
          // dispatch(setNavLogo(verticalRed));
          console.log("found Div for nav!", navMenu.current);
        } else {
          // dispatch(setNavLogo(vertical));
          // navMenu.current = vertical;
          var nav = (navMenu.current = "black");
          dispatch(setMenuColor(nav));
          console.log("remove!", navMenu.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (navMenu.current) {
      observer.observe(navMenu.current);
    }
  }, [navMenu]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className="footerMain"
    >
      {/* {nameUrl === "http://localhost:3000/contact" ? <Navbar /> : null} */}
      <Row>
        <Col xs={24} sm={24} md={14} lg={16} xl={16}>
          <div className="footerContentDiv">
            <div className="contactForm">
              <h2 className="mainHeadings">Get a Quote</h2>
              {/* <div className="footerSubTitle"> */}
                <h4 className="mainDescription">Tell us about your project. We’ll be in touch soon.</h4>
              {/* </div> */}  
              <div className="form">
                <Form
                  {...layout}
                  name="contact-info"
                  onFinish={onSubmitForm}
                  form={form}
                  layout="vertical"
                  preserve={false}
                  ref={formMail}
                >
                  <Row justify="">
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="cust_name"
                        // label="Address"
                        // rules={[{ required: true }]}
                        labelAlign="left"
                        className="contactField"
                      >
                        <Input placeholder="Your Name" bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="cust_email"
                        // label="Email"
                        rules={[
                          { type: "email", message: "Enter valid email" },
                        ]}
                        labelAlign="left"
                        className="contactField"
                      >
                        <Input placeholder="Your Email" bordered={false} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="cust_interest"
                        // label="Social Media"
                        // rules={[{ required: true }]}
                        labelAlign="left"
                        className="contactField"
                      >
                        <Select
                          bordered={false}
                          // defaultValue="Which Service Are You Interested In?"
                          placeholder="Which Service Are You Interested In?"
                          options={[
                            // {
                            //   value: "Which Service Are You Interested In?",
                            //   label: "Which Service Are You Interested In?",
                            // },
                            {
                              value: "UI/UX Design",
                              label: "UI/UX Design",
                            },
                            {
                              value: "Brand Design",
                              label: "Brand Design",
                            },
                            {
                              value: "Content Development",
                              label: "Content Development",
                            },
                            {
                              value: "Lead Generation & Performance Marketing",
                              label: "Lead Generation & Performance Marketing",
                            },
                            {
                              value: "Presentation & Pitch Deck Design",
                              label: "Presentation & Pitch Deck Design",
                            },
                            {
                              value: "Web Design & Development",
                              label: "Web Design & Development",
                            },
                            {
                              value: "Social Media Marketing",
                              label: "Social Media Marketing",
                            },
                            {
                              value: "Software Development",
                              label: "Software Development",
                            },
                            {
                              value: "Photography & Videography",
                              label: "Photography & Videography",
                            },
                            {
                              value: "Brand & Animation",
                              label: "Brand & Animation",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="cust_budget"
                        // label="Social Media"
                        // rules={[{ required: true }]}
                        labelAlign="left"
                        className="contactField"
                      >
                        <Select
                          bordered={false}
                          // defaultValue="$500 - $5,000"
                          placeholder="Please Select the budget"
                          options={[
                            {
                              value: "$500 - $5,000",
                              label: "$500 - $5,000",
                            },
                            {
                              value: "$5,000 - $20,000",
                              label: "$5,000 - $20,000",
                            },
                            {
                              value: "$20,000 - $50,000",
                              label: "$20,000 - $50,000",
                            },
                            {
                              value: "$50,000 +",
                              label: "$50,000 +",
                            },
                            {
                              value: "Request Budget Guidance",
                              label: "Request Budget Guidance",
                            },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="cust_phone"
                        // label="Contact#"
                        // rules={[{ required: true }]}
                        labelAlign="left"
                        className="contactField"
                      >
                        <InputMask
                          mask="+\929999999999"
                          placeholder="Your Phone"
                          bordered={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="cust_company"
                        // label="Email"
                        labelAlign="left"
                        className="contactField"
                      >
                        <Input
                          placeholder="Your Company/Organization"
                          bordered={false}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                      <Form.Item
                        name="cust_address"
                        // label="Address"
                        // rules={[{ required: true }]}
                        labelAlign="left"
                        className="contactField"
                      >
                        <TextArea
                          placeholder="Let us know a bit more about the project you have in mind..."
                          bordered={false}
                          style={{
                            // height: 60,
                            resize: "none",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                      <Form.Item
                        name="cust_socialMedia"
                        // label="Social Media"
                        // rules={[{ required: true }]}
                        labelAlign="left"
                        className="contactField"
                      >
                        <Input
                          placeholder="Enter Social Media Link"
                          bordered={false}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      xs={24}
                      sm={24}
                      md={24}
                      lg={24}
                      xl={24}
                      style={{
                        display: "flex",
                        // justifyContent: "space-around",
                      }}
                    >
                      <div className="contactFormBtn">
                        <Button htmlType="submit" className="btn" loading={loading}>
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={10} lg={8} xl={8}>
          <div className="footerContentDiv2" >
            <div className="logoChange" ref={navRef}></div>
            <div className="copyRightsDiv">
              <div ref={navMenu}>Copyright © 2022 Cubes&Dots All rights reserved.</div>
              <Link to="/privacy_policy" className="link">
                <div className="privacyPolicy">Privacy Policy</div>
              </Link>
            </div>
            <div className="details">
              <div style={{ fontWeight: "700" }}>
                Contact Us
              </div>
              <div className="email">
                <a className="link" href="mailto:hello@cubesanddots.com">
                  hello@cubesanddots.com
                </a>
              </div>
              {/* {address.map((data, index) => ( */}
                {/* <div className="address">
                  <div className="subAddress">
                    <span>34 N Franklin Ave 687 2404 Pinedale WY 82941</span>
                    <span className="line">|</span>
                    <span>USA</span>
                  </div>
                </div> */}
              {/* <div className="peopleDetailing">
                {people.map((data, index) => (
                  <div className="peopleInfo" key={index}>
                    <div>
                      <Avatar size={60} src={data.img} />
                    </div>
                    <div className="peopleName">
                      <div style={{ fontWeight: "700", fontSize: "1.3rem" }}>
                        {data.name}
                      </div>
                      <div style={{ fontWeight: "600" }}>
                        {data.designation}
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}

export default Footers;
