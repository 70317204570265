import React from "react";
import { motion } from "framer-motion";
import "../WhatWeDo.css";
import ReactFullpage from "@fullpage/react-fullpage";
import alfalah from "../../../Images/alfalah.webp";
import alfalahMobile from "../../../Images/alfalahMobile.webp";
import bays from "../../../Images/Bays.webp";
import baysMobile from "../../../Images/BaysMobile.webp";
import daaman from "../../../Images/Daaman.png";
import daamanMobile from "../../../Images/DaamanMobile.png";
import khelo from "../../../Images/khelo.png";
import kheloMobile from "../../../Images/kheloMobile.png";
import rocksmit from "../../../Images/rocksmit.png";
import rocksmitMobile from "../../../Images/rocksmitMobile.png";
import serene from "../../../Images/serene.png";
import sereneMobile from "../../../Images/sereneMobile.png";
import shaheen from "../../../Images/shaheen.png";
import shaheenMobile from "../../../Images/shaheenMobile.png";
import tooMuch from "../../../Images/Toomuch.png";
import tooMuchMobile from "../../../Images/ToomuchMobile.png";

function WebDesign() {
  const brandPages = [
    {
      id: "1",
      classes: "brandDesignMainDiv wD2",
      image1: bays,
      image2: baysMobile,
      logoName: "",
      title: "Bays Lingerie",
      subTitle: "We opt for the compelling mixture of content and functionality for web design and development. Bays’ concept web design was evolved with maximized attraction and retention of customers through functional interface. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "2",
      classes: "brandDesignMainDiv wD1",
      image1: alfalah,
      image2: alfalahMobile,
      logoName: "",
      title: "Alfalah",
      subTitle: "Our tech architect builds web app platforms with updated database architecture knowledge to find you the adequate business solutions. The concept design of Alfalah web app was engineered with easy to connect, customer focal, and accelerated user experience approach. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "3",
      classes: "brandDesignMainDiv wD3",
      image1: daaman,
      image2: daamanMobile,
      logoName: "",
      title: "Daaman",
      subTitle: "We begin the web development with extensive research and analysis to identify clients and user needs and then foster the interface addressing them. The web app of our client Daaman was engineered which is a highly intuitive web design meeting their business needs. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "4",
      classes: "brandDesignMainDiv wD4",
      image1: khelo,
      image2: kheloMobile,
      logoName: "",
      title: "Khelo Kricket",
      subTitle: "From satisfying your business needs to help you run a creative venture, we develop user focused web applications that engage. The web application Khelo was crafted for our client which covers everything a Sport lover craves to know about Cricket. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "5",
      classes: "brandDesignMainDiv wD4",
      image1: rocksmit,
      image2: rocksmitMobile,
      logoName: "",
      title: "Rocksmith",
      subTitle: "Whether it is the elevation of existing product or building custom and powerful web application, we do it all. We built the web application for RockSmith through creating a unified approach to reach their business goals.",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "6",
      classes: "brandDesignMainDiv wD5",
      image1: serene,
      image2: sereneMobile,
      logoName: "",
      title: "Serene Air",
      subTitle: "Our tech geek who are skilled in information architecture  takes your product/service vision to the next level through simplified and engaging web interface. We crafted the concept wed design of Serene Air which is not only functional but impactful.",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "7",
      classes: "brandDesignMainDiv wD6",
      image1: shaheen,
      image2: shaheenMobile,
      logoName: "",
      title: "Shaheen Air ",
      subTitle: "We understand the needs and challenges of our website users through building in-depth personas and sub-personas. The concept web design for Shaheen Air was built with upgraded user friendly interface and accelerated user experience addressing the primary and major business concerns. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "8",
      classes: "brandDesignMainDiv wD67",
      image1: tooMuch,
      image2: tooMuchMobile,
      logoName: "",
      title: "Too Much",
      subTitle: "We align your web app with the trajectory of your business goals and turn the user engagement into action. The web platform for Too Much was evolved through strategic planning to maintaining a consistent user experience across the website.",
      textColor: "textColor2",
      text: "",
    },
  ];
  return (
    <>
      <ReactFullpage
        // anchors={anchors}
        navigation
        // navigationTooltips={anchors}
        render={({ state, fullpageApi }) => {
          return (
            <div>
              {brandPages.map((data, index) => (
                <div className="section" key={index}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className={data.classes}
                  >
                    <div className="brandBannerDiv">
                      <img
                        src={data.image1}
                        alt={data.logoName}
                        className="webImg"
                      />
                    </div>
                    <div className="brandMobileBannerDiv">
                      <img
                        src={data.image2}
                        alt={data.logoName}
                        className="webMobImg"
                      />
                    </div>
                    {/* <div className="brandBgColor"></div> */}
                    <div className="brandImgBanner">
                      <div
                        className={`brandInfoDiv ${data.textColor} ${data.text}`}
                      >
                        <div className={`brandLogo`}>{data.logoName}</div>
                        <h2 className="mainHeadings">
                          <div className={`brandTitle`}>{data.title}</div>
                        </h2>
                        <div className={`brandSubTitle mainDescription`}>{data.subTitle}</div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              ))}
            </div>
          );
        }}
      />
    </>
  );
}

export default WebDesign;
