import { NavRefType } from "../constants/NavRefType";
import vertical from "../../Images/cubicVertical.svg";
const initialState = {
  navLogoPayload: vertical,
  menuColorPayload: "black",
  sliderState: false,
  categoryState : false,
};

export const navReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case NavRefType.GET_NAV_LOGO:
      // first it will take the initial state and then we are setting the update payload to product[]
      return { ...state, navLogoPayload: payload };
    case NavRefType.GET_MENU_COLOR:
      return { ...state, menuColorPayload: payload };
    case NavRefType.OPEN_SLIDER:
      return { ...state, sliderState: payload };
    case NavRefType.OPEN_WHAT_WE_DO:
      return { ...state, categoryState: payload };
    default:
      return state;
  }
};
