import React from "react";
import Screen1 from "../../SubComponents/Screen1/Screen1";
import Screen2 from "../../SubComponents/Screen2/Screen2";
import Footers from "../Footer/Footers";
import Navbar from "../NavBar/Navbar";
import "../NavBar/Navbar.css";
import ReactFullpage from "@fullpage/react-fullpage";
import { motion } from "framer-motion";
import Screen3 from "../../SubComponents/Screen3/Screen3";
import Blog from "../Blog/Blog";
function Home({navRef}) {
  const anchors = ["Works&Verticals", "OurCompany", "Blog", "ContactUs"];
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, }}
      transition={{ delay: 0.2 }}
      className="parentDiv"
    >
      {/* <Navbar navRef={navbarLogo}/> */}
      <ReactFullpage
        // anchors={anchors}
        navigation
        // navigationTooltips={anchors}
        // sectionsColor={["#282c34", "#ff5f45", "#0798ec"]}
        onLeave={(origin, destination, direction) => {
          // console.log("onLeave event", { origin, destination, direction });
        }}
        render={({ state, fullpageApi }) => {
          // console.log("render prop change", state, fullpageApi); // eslint-disable-line no-console

          return (
            <div>
              <div className="section">
                <Screen1 />
              </div>
              <div className="section">
                <Screen2 navRef={navRef} />
              </div>
              {/* <div className="section">
                <Screen3 />
              </div> */}
              <div className="section">
                <Blog />
              </div>
              <div className="section">
                <Footers navRef={navRef}/>
              </div>
            </div>
          );
        }}
      />

      {/* <Navbar/>
      <Screen1/>
      <Screen2/>
      <Footers/> */}
    </motion.div>
  );
}

export default Home;
