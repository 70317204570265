import { Button, Col, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";
import "./Screen1.css";
import backvideo from "../../Videos/cubesDotVideo.mp4";
import backvideo2 from "../../Videos/CubesDotVideo2.mp4";
import { AnimatePresence, motion } from "framer-motion";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { IoClose, IoMenuSharp } from "react-icons/io5";
import { useDispatch } from "react-redux";
import { categoryOpen, sliderOpen } from "../../Redux/actions/NavRefAction";

const variants = {
  open: {
    x: 0,
    transition: { delay: 0.2, ease: "easeIn", duration: "0.7" },
  },
  closed: {
    x: 4000,
    // opacity:0,
    transition: { delay: 0.5, type: "tween", ease: "easeOut", duration: "1" },
  },
  none: {
    x: 4000,
  },
  exit: {
    opacity: 0,
    y: -200,
    scale: 0.8,
    transition: { delay: 0.5, type: "tween", ease: "easeOut", duration: "1" },
  },
  exit2: {
    opacity: 0,
    y: -600,
    scale: 0.8,
    transition: { delay: 0.5, type: "tween", ease: "easeOut", duration: "1" },
  },
  on: {
    opacity: 1,
  },
  off: {
    opacity: 0,
  },
};

function Screen1() {
  const ref = useRef();
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [backHover, setBackHover] = useState(false);
  const [open, setOpen] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);

        if (entry.isIntersecting) {
          //do your actions here
          ref.current.play();
          console.log("It works!", ref.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);
  return (
    <>
      <div className="screen1Main">
        <div className="screen1ContentDiv">
          <div className="screen1SubContentDiv">
            <h2 className="mainHeadings">
              <div className="">
                Turn Ideas into
                <br />
                Experiences
              </div>
            </h2>
            <div className="screen1Btn">
              <Button type="primary" className="Btn" onClick={() => {dispatch(sliderOpen(true)); dispatch(categoryOpen(true));} }>
                The Work & Verticals
              </Button>
            </div>
          </div>
        </div>
        <div className="screen1Bg">.</div>
        <div className="screen1VideoCard">
          <video ref={ref} className="screen1VideoTag" loop muted>
            <source src={backvideo2} type="video/mp4" />
          </video>
        </div>
      </div>
    </>
  );
}

export default Screen1;
