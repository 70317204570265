import {axiosInstance} from './FetchInterceptor'


export class HttpService {

    constructor(url_prefix = "") {
        this.url_prefix = url_prefix
    }

    async get(apiUrl, queryParams = null) {
        try {
            console.log("Request GET URL: " + apiUrl);
            console.log("Request GET Params: " + JSON.stringify(queryParams));
            return await axiosInstance({
                baseURL: process.env.REACT_APP_BASE_URL_LIVE,
                url: apiUrl,
                method: 'get',
                params:this.mapQueryParams(queryParams)
              }).then(function (response) {
                //   console.debug("Response: " + JSON.stringify(response));
                  return response;
              });
        } catch (error) {
            console.debug(error)
            return null
        }
    }

    async post(apiUrl, body, queryParams = null) {
        try {
            console.log("Request GET URL: " + apiUrl);
            console.log("Request GET Params: " + JSON.stringify(queryParams));
            return await axiosInstance({
                baseURL: "https://cubesanddots.com/api/",
                url: apiUrl,
                method: 'POST',
                params:this.mapQueryParams(queryParams),
                data: body
              }).then(function (response) {
                // console.debug("Response: " + JSON.stringify(response));
                return response;
            });
        } catch (error) {
            console.log(error);
            return null
        }

    }

    async put(apiUrl, body, queryParams = null) {
        try {
            return fetch({
                baseURL: process.env.REACT_APP_BASE_URL_LIVE,
                url: apiUrl,
                method: 'put',
                data: body
              })
        } catch (error) {
            console.log(error);
            return null
        }
    }

    // getUrl(url) {
    //     // return this.url_prefix + url
    //     return  url
    // }

    mapQueryParams(queryParams) {
        return queryParams
            ? Object.keys(queryParams).map(function (key) {
                return key + '=' + queryParams[key]
            }).join('&')
            : ""
    }
}