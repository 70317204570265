import { Carousel, Col, Row, Skeleton } from "antd";
import React from "react";
import "./Blog.css";
import { BsArrowRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import { caseStudyData } from "../CaseStudy/CaseStudiesData";

function Blog() {
  // const blogPost = [
  //   {
  //     id: 1,
  //     image: <Skeleton.Image active={true} />,
  //     text: "46 ideas for Elon Musk and Tesla",
  //     author: "by Navroz Mansiya",
  //   },
  //   {
  //     id: 2,
  //     image: <Skeleton.Image active={true} />,
  //     text: "Define and live by your values. what are we going to do in future.",
  //     author: "by Navroz Mansiya",
  //   },
  //   {
  //     id: 3,
  //     image: <Skeleton.Image active={true} />,
  //     text: "Join us from your country",
  //     author: "by Navroz Mansiya",
  //   },
  // ];

  //   const contentStyle = {
  //     margin: 0,
  //     height: "160px",
  //     color: "#fff",
  //     lineHeight: "160px",
  //     textAlign: "center",
  //     background: "#364d79",
  //   };

  //   const onChange = (currentSlide) => {
  //     console.log(currentSlide);
  //   };
  return (
    <div className="blogMainDiv">
      <Row justify="center" style={{width:"100%"}}>
        <Col xs={22} sm={22} md={20} lg={20} xl={20}>
          <h2 className="mainHeadings"><div className="blogTitle">From Our Stories</div></h2>
          <div className="blogSubTitle mainDescription">
            {/* The less boring side of brand experience. */}
            Turning Ideas into Visual Masterpieces
          </div>
          <div className="blogLink">
            <Link to="/case_studies" className="link">
              Read Case Study &nbsp; <BsArrowRight size={25} />
            </Link>
          </div>
        </Col>

        {/* <Col xs={22} sm={22} md={13} lg={13} xl={13}>
          <div className="blogSubTitle mainDescription">
            The less boring side of brand experience.
          </div>
        </Col> */}
        {/* <Col xs={22} sm={22} md={13} lg={13} xl={13}>
          <div className="blogLink">
            <Link to="/case_studies" className="link">
              Read Case Study &nbsp; <BsArrowRight size={25} />
            </Link>
          </div>
        </Col> */}

        <Col xs={22} sm={22} md={22} lg={15} xl={15}>
          <Row justify="center" gutter={[16, 16]}>
            {caseStudyData.map((data, index) => (
              <Col xs={12} sm={8} md={8} lg={8} xl={8} key={index}>
                <div className="blog">
                <Link to={`/case_studies/${data.id}`} state={{ data: data }}>
                <>
                  <div className="blogImage">{data.image === "" ? <Skeleton.Image active={true} /> : <img src={data.image} alt="Case Study Pic" height="90%" width="90%" style={{borderRadius:"5px"}}/> }</div>
                  <div className="blogText blogHover" dangerouslySetInnerHTML={{ __html: data.title }}/>
                </>
                </Link>
                  <div className="blogText">{data.author}</div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Blog;
