import {HttpService} from "../Auth/HttpServices";
const url_prefix = 'api/';


export class CustomerService {

    
    http
    constructor() {
        this.http = (new HttpService(url_prefix))
    }

    // async getUser() {
    //     return await this.http.get(`HospitalList`);
    // }

    async sendCustomerData(data) {
        return await this.http.post(`add_customer`,data);
    }
    async sendCandidateData(data) {
        return await this.http.post(`add_candidate`,data);
    }

}
