import React, { useState } from "react";
import "./Screen3.css";
import fantasyLogo from "../../Images/fantasyLogo.svg";
import cubicLogo from "../../Images/cubicLogo.png";
import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { CgMore } from "react-icons/cg";
import Strategy from "../../Images/Strategy.jpg";
import Ideation from "../../Images/Ideation.jpg";
import Creativity from "../../Images/Creativity.jpg";
import Purpose from "../../Images/Purpose.jpg";
function Screen3() {
  const [hover, setHover] = useState(false);
  const dnaCategory = [
    {
      id: 1,
      name: "Creativity",
      image: Creativity,
      color: "pink",
      align: "center",
    },
    { id: 2, name: "Ideation", image: Ideation, color: "red", align: "start" },
    { id: 3, name: "Strategy", image: Strategy, color: "green", align: "center" },
    {
      id: 4,
      name: "Purpose - driven",
      image: Purpose,
      color: "yellow",
      align: "start",
    },
  ];
  return (
    <div className="screen3Main">
      <Row>
        <Col xs={24} sm={10} md={10} lg={12} xl={12}>
          <div className="screen3ContentDiv">
            <div className="subContentDiv3">
              <h2 className="mainHeadings">Our DNA</h2>
              <div className="dnaSubTitle mainDescription">
                We find the essential DNA of your brand and develop creative
                strategies to bring your unique qualities to the
                forefront.&nbsp;
              </div>
            </div>
          </div>
        </Col>

        <Col xs={24} sm={14} md={14} lg={12} xl={12}>
          <div className="screen3ContentDivMain">
            <div className="screen3ContentDiv2">
              <div className="dnaTypesDiv">
                <Row gutter={[24, 24]} justify="center">
                  {dnaCategory.map((data) => (
                    <Col
                      xs={11}
                      sm={11}
                      md={11}
                      lg={9}
                      xl={9}
                      key={data.id}
                      // style={{backgroundColor:data.color}}
                    >
                      <div
                        className="dnaTypeInnerDiv"
                        // style={{ justifyContent: data.align }}
                      >
                        <div className="dnaTypesName">
                          <div className="dnaImage">
                            <img
                              src={data.image}
                              height="100%"
                              width="100%"
                              alt="Logo"
                              style={{ borderRadius: "1rem" }}
                            />
                          </div>
                          <div className="dnaBgColor">.</div>
                          <div className="dnaName">{data.name}</div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Screen3;
