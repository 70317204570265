import React from "react";
import { motion } from "framer-motion";
import "../WhatWeDo.css";
import ReactFullpage from "@fullpage/react-fullpage";
import bykea from "../../../Images/bykea.webp";
import bykeaMobile from "../../../Images/bykeaMobile.webp";
import durex from "../../../Images/durex.webp";
import durexMobile from "../../../Images/durexMobile.webp";
import MH from "../../../Images/MH.webp";
import MHMobile from "../../../Images/MHMobile.webp";
import unilever from "../../../Images/unilever.webp";
import unileverMobile from "../../../Images/unileverMobile.webp";

function AppDesign() {
  const brandPages = [
    {
      id: "1",
      classes: "brandDesignMainDiv aD1",
      image1: bykea,
      image2: bykeaMobile,
      logoName: "",
      title: "Bykea",
      subTitle:
        "We develop digital apps with enhanced brand resonance, simplified engagement, and always front of mind app design. We crafted concept design of Bykea interface with elevated user experience and maximized user engagement. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "2",
      classes: "brandDesignMainDiv aD2",
      image1: durex,
      image2: durexMobile,
      logoName: "",
      title: "Durex",
      subTitle:
        "We work closely with clients to design tech loaded engaging apps to meet general principles and standards of good praxis. A concept design of Durex app was developed with identified user needs and quality assurance.",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "3",
      classes: "brandDesignMainDiv aD3",
      image1: MH,
      image2: MHMobile,
      logoName: "",
      title: "Mentor Health",
      subTitle:
        "We developed a far-reaching user interface for Mentor Health incorporating their 360° digital health services. The app is a face of user centric design with intuitive and engaging yet user friendly interface for elevated user experience.",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "4",
      classes: "brandDesignMainDiv aD4",
      image1: unilever,
      image2: unileverMobile,
      logoName: "",
      title: "Unilever",
      subTitle:
        "We believe in pushing the boundaries and making your target users have the best experience through seamless and flawless application interface. We designed the Unilever app interface which aimed at driving right outcome for users and business owners. ",
      textColor: "textColor2",
      text: "",
    },
  ];
  return (
    <>
      <ReactFullpage
        // anchors={anchors}
        navigation
        // navigationTooltips={anchors}
        render={({ state, fullpageApi }) => {
          return (
            <div>
              {brandPages.map((data, index) => (
                <div className="section" key={index}>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 0.2 }}
                    className={data.classes}
                  >
                    <div className="brandBannerDiv">
                      <img
                        src={data.image1}
                        alt={data.logoName}
                        className="appImg"
                      />
                    </div>
                    <div className="brandMobileBannerDiv">
                      <img
                        src={data.image2}
                        alt={data.logoName}
                        className="appMobImg"
                      />
                    </div>
                    {/* <div className="brandBgColor"></div> */}
                    <div className="brandImgBanner">
                      <div
                        className={`brandInfoDiv ${data.textColor} ${data.text}`}
                      >
                        <div className={`brandLogo`}>{data.logoName}</div>
                        <h2 className="mainHeadings">
                          <div className={`brandTitle`}>{data.title}</div>
                        </h2>
                        <div className={`brandSubTitle mainDescription`}>
                          {data.subTitle}
                        </div>
                      </div>
                    </div>
                  </motion.div>
                </div>
              ))}
            </div>
          );
        }}
      />
    </>
  );
}

export default AppDesign;
