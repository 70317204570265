import React, { useEffect, useRef } from "react";
import "./PresentationTemplate.css";
import { Col, Row } from "antd";
import { setNavLogo } from "../../../Redux/actions/NavRefAction";
import { useDispatch } from "react-redux";
import vertical from "../../../Images/cubicVertical.svg";
import verticalRed from "../../../Images/cubicVertical_red.svg";

function PresentationTemplate2({
  presentationTitle,
  presentationDescription,
  presentationImage,
}) {

  const dispatch = useDispatch();
    const navRef = useRef();
    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            console.log("track intersection==", entry);
    
            if (entry.isIntersecting) {
              //do your actions here
              // navRef.current = square;
              dispatch(setNavLogo(`${verticalRed}`));
              console.log("found Div for nav!", navRef.current);
            } else {
              dispatch(setNavLogo(`${vertical}`));
              console.log("remove!", navRef.current);
            }
          },
          {
            root: null,
            rootMargin: "0px",
            threshold: 0.1,
          }
        );
        if (navRef.current) {
          observer.observe(navRef.current);
        }
      }, [navRef]);
  return (
    <div className="presentationMainDiv">
      <Row className="presentationTemplate2Row">
        <Col xs={24} sm={24} md={16} lg={16} xl={16}>
          <div className="presentationTemplate2Col2">
            <div className="presentationImageDiv" style={{marginLeft:"-1rem"}}>
              <img
                src={presentationImage}
                alt="presentations"
                height="100%"
                width="100%"
              />
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <div className="presentationCol1 presentationTextAlignment presentationBgColor2">
          {/* <div ref={navRef} className="brandNavLogo"/> */}
            <div className="subContentDiv3">
              <h2 className="subHeadings" style={{ color: "white" }}>
                {presentationTitle}
              </h2>
              <div
                className="dnaSubTitle subDescription"
                style={{ color: "white" }}
              >
                {presentationDescription}
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default PresentationTemplate2;
