import React from "react";
import { motion } from "framer-motion";
import "../WhatWeDo.css";
import apnataxLogo from "../../../Images/apnataxLogo.svg";
import ReactFullpage from "@fullpage/react-fullpage";
import apnataxMobile from "../../../Images/ApnataxMobile3.png";
import goodMoringaMobile from "../../../Images/GoodmoringaMobile3.png";
import payProMobile from "../../../Images/payproMobile.png";
import hypeMobile from "../../../Images/HypeMobile.png";
import changanMobile from "../../../Images/changanMobile.webp";
// import amiamo from "../../../Images/amiamo.webp";
import amiamoMobile from "../../../Images/amiamoMobile.webp";
// ------
import apnatax from "../../../Images/Apnatax(New).webp";
import goodMoringa from "../../../Images/Goodmoringa(New).webp";
import payPro from "../../../Images/paypro(New).webp";
import hype from "../../../Images/Hype(New).webp";
import amiamo from "../../../Images/amiamo(New).webp";
import changan from "../../../Images/changan(New).webp";
import BrandTemplate1 from "./BrandTemplate1";
import BrandTemplate2 from "./BrandTemplate2";

function BrandDesign() {
  const anchors = ["Apnatax", "Goodmoringa", "Paypro", "Hype"];
  const brandPages = [
    {
      id: "1",
      classes: "brandDesignMainDiv bD1",
      image1: apnatax,
      image2: apnataxMobile,
      logoName: "",
      title: "apnatax",
      subTitle:
        "A team of specialists driven by a simple goal. Bring Pakistan the best tax return filing service on the market.",
      textColor: "textColor1",
      text: "",
    },
    {
      id: "2",
      classes: "brandDesignMainDiv bD2",
      image1: goodMoringa,
      image2: goodMoringaMobile,
      logoName: "",
      title: "Good Moringa ",
      subTitle:
        "We begin your branding with telling your story to captivate the audience and make the impactful connection. We designed the packaging of Good Moringa keeping the best packaging design strategy in view to enhance the product visibility and brand resonance.",
      textColor: "textColor1",
      text: "",
    },
    {
      id: "3",
      classes: "brandDesignMainDiv bD3",
      image1: payPro,
      image2: payProMobile,
      logoName: "",
      title: "paypro",
      subTitle:
        "We develop the brand identity from brand awareness to brand loyalty in the coolest way possible. From branding to web app development, PayPro is one of our clients whose intuitive and customer focused interface meets the clients and bring them home in one click.",
      textColor: "textColor1",
      text: "",
    },
    {
      id: "4",
      classes: "brandDesignMainDiv bD4",
      image1: hype,
      image2: hypeMobile,
      logoName: "",
      title: "Hype",
      subTitle:
        "Get your product/service to be seen, clicked, shared, and admired. We enabled our client Hype in aligning its brand strategy with business strategy to grow and meet their business goals through branding and stylescaping.   ",
      textColor: "textColor1",
      text: "",
    },
    {
      id: "5",
      classes: "brandDesignMainDiv bD5",
      image1: changan,
      image2: changanMobile,
      logoName: "",
      title: "Changan",
      subTitle:
        "We create experiences and captivate the audience through key visual designs. Our team of experts are consumed with the content and engineered key visual design for Changan by following the simple yet meaningful visual art approach. ",
      textColor: "textColor1",
      text: "blackText",
    },
    {
      id: "6",
      classes: "brandDesignMainDiv bD6",
      image1: amiamo,
      image2: amiamoMobile,
      logoName: "",
      title: "Amiamo",
      subTitle:
        "Reach your product to the targeted audience with trending marketing strategies and grow your brand. We developed a captivating social media marketing strategy for Amiamo and enabled them enhance their Return on Investment (ROI) through refined and upskilled advertising. ",
      textColor: "textColor2",
      text: "",
    },
  ];
  return (
    <>
      <ReactFullpage
        navigation
        render={({ state, fullpageApi }) => {
          return (
            // <div>
            // {brandPages.map((data, index)=>(
            //   <div className="section" key={index}>
            //     <motion.div
            //       initial={{ opacity: 0 }}
            //       animate={{ opacity: 1 }}
            //       transition={{ delay: 0.2 }}
            //       className={data.classes}
            //     >
            //       <div className="brandBannerDiv">
            //         <img src={data.image1} alt={data.logoName} className="brandImg" />
            //       </div>
            //       <div className="brandMobileBannerDiv">
            //         <img
            //           src={data.image2}
            //           alt={data.logoName}
            //           className="brandMobImg"
            //         />
            //       </div>
            //       <div className="brandImgBanner">
            //         <div className={`brandInfoDiv ${data.textColor} ${data.text}`}>
            //           <div className={`brandLogo`}>
            //             {data.logoName}
            //           </div>
            //           <div className={`brandTitle`}>
            //             {data.title}
            //           </div>
            //           <div className={`brandSubTitle`}>
            //             {data.subTitle}
            //           </div>
            //         </div>
            //       </div>
            //     </motion.div>
            //   </div>
            // ))}
            // </div>
            <motion.div
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
              style={{ backgroundColor: "black" }}
            >
              <div className="section">
                <BrandTemplate1
                  brandTitle={"Amiamo"}
                  brandDescription={
                    "Reach your product to the targeted audience with trending marketing strategies and grow your brand. We developed a captivating social media marketing strategy for Amiamo and enabled them enhance their Return on Investment (ROI) through refined and upskilled advertising."
                  }
                  brandImage={amiamo}
                />
              </div>
              <div className="section">
                <BrandTemplate1
                  brandTitle={"Changan"}
                  brandDescription={
                    "We create experiences and captivate the audience through key visual designs. Our team of experts are consumed with the content and engineered key visual design for Changan by following the simple yet meaningful visual art approach. "
                  }
                  brandImage={changan}
                />
              </div>
              <div className="section">
                <BrandTemplate1
                  brandTitle={"Hype"}
                  brandDescription={
                    "Get your product/service to be seen, clicked, shared, and admired. We enabled our client Hype in aligning its brand strategy with business strategy to grow and meet their business goals through branding and stylescaping."
                  }
                  brandImage={hype}
                />
              </div>
              <div className="section">
                <BrandTemplate1
                  brandTitle={"paypro"}
                  brandDescription={
                    "We develop the brand identity from brand awareness to brand loyalty in the coolest way possible. From branding to web app development, PayPro is one of our clients whose intuitive and customer focused interface meets the clients and bring them home in one click."
                  }
                  brandImage={payPro}
                />
              </div>
              <div className="section">
                <BrandTemplate1
                  brandTitle={"Good Moringa"}
                  brandDescription={
                    "We begin your branding with telling your story to captivate the audience and make the impactful connection. We designed the packaging of Good Moringa keeping the best packaging design strategy in view to enhance the product visibility and brand resonance."
                  }
                  brandImage={goodMoringa}
                />
              </div>
              <div className="section">
                <BrandTemplate1
                  brandTitle={"apnatax"}
                  brandDescription={
                    "A team of specialists driven by a simple goal. Bring Pakistan the best tax return filing service on the market."
                  }
                  brandImage={apnatax}
                />
              </div>
            </motion.div>
          );
        }}
      />
    </>
  );
}

export default BrandDesign;
