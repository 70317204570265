import React from "react";
import { motion } from "framer-motion";
import "../WhatWeDo.css";
import ReactFullpage from "@fullpage/react-fullpage";
// import asaan from "../../../Images/asaan.png";
import asaanMobile from "../../../Images/asaanMobile.png";
import HMIMobile from "../../../Images/HMIMobile.png";
// import paypro from "../../../Images/paypro.png";
import paypro2Mobile from "../../../Images/Paypro2Mobile.png";
// import HMI from "../../../Images/HMI.png";
// ----------
import asaan from "../../../Images/asaan(New).webp";
import HMI from "../../../Images/HMI(New).webp";
import paypro from "../../../Images/payproPresentation.webp";
import PresentationTemplate1 from "./PresentationTemplate1";
import PresentationTemplate2 from "./PresentationTemplate2";

function PresentationDesign() {
  const brandPages = [
    {
      id: "1",
      classes: "brandDesignMainDiv pD1",
      image1: asaan,
      image2: asaanMobile,
      logoName: "",
      title: "Asaan",
      subTitle:
        "It’s not just what you say that matters, it’s how you say it. A pitch deck design was developed and presented by our professionals for Asaan Tax demonstrating why it is the ultimate choice when it comes to easy tax filing, planning and refunds. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "2",
      classes: "brandDesignMainDiv pD2",
      image1: HMI,
      image2: HMIMobile,
      logoName: "",
      title: "HMI",
      subTitle:
        "Give a huge boost and a competitive edge to your ventures with our pitch deck designer and presenters through fully engaged audience and industry leading presentations, and powerful and persuasive pitches. We designed a pitch deck for HMI Ventures who is an experienced venture builders and investing in and growing early technology. ",
      textColor: "textColor2",
      text: "",
    },
    {
      id: "3",
      classes: "brandDesignMainDiv pD3",
      image1: paypro,
      image2: paypro2Mobile,
      logoName: "",
      title: "Paypro",
      subTitle:
        "We believe in presenting better and winning more through building deeper customer relationship and capturing hearts and minds at every single time. Our pitch deck design for Paypro was crafted that is impacting digitization of Pakistan through its online business transactions solution.",
      textColor: "textColor2",
      text: "",
    },
  ];
  return (
    <>
      <ReactFullpage
        // anchors={anchors}
        navigation
        // navigationTooltips={anchors}
        render={({ state, fullpageApi }) => {
          return (
            // <div>
            // {brandPages.map((data, index)=>(
            //   <div className="section" key={index}>
            //     <motion.div
            //       initial={{ opacity: 0 }}
            //       animate={{ opacity: 1 }}
            //       transition={{ delay: 0.2 }}
            //       className={data.classes}
            //     >
            //       <div className="brandBannerDiv">
            //         <img src={data.image1} alt={data.logoName} className="brandImg" />
            //       </div>
            //       <div className="brandMobileBannerDiv">
            //         <img
            //           src={data.image2}
            //           alt={data.logoName}
            //           className="appMobImg"
            //         />
            //       </div>
            //       {/* <div className="brandBgColor"></div> */}
            //       <div className="presentationImgBanner">
            //         <div className={`brandInfoDiv ${data.textColor} ${data.text}`}>
            //           <div className={`brandLogo`}>
            //             {data.logoName}
            //           </div>
            //           <div className={`brandTitle`}>
            //             {data.title}
            //           </div>
            //           <div className={`brandSubTitle`}>
            //             {data.subTitle}
            //           </div>
            //         </div>
            //       </div>
            //     </motion.div>
            //   </div>
            // ))}
            // </div>
            <motion.div
              initial={{ opacity: 0.5 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.8 }}
            >
              <div className="section">
                <PresentationTemplate1
                  presentationTitle={"Paypro"}
                  presentationDescription={
                    "We believe in presenting better and winning more through building deeper customer relationship and capturing hearts and minds at every single time. Our pitch deck design for Paypro was crafted that is impacting digitization of Pakistan through its online business transactions solution."
                  }
                  presentationImage={paypro}
                />
              </div>
              <div className="section">
                <PresentationTemplate2
                  presentationTitle={"HMI"}
                  presentationDescription={
                    "Give a huge boost and a competitive edge to your ventures with our pitch deck designer and presenters through fully engaged audience and industry leading presentations, and powerful and persuasive pitches. We designed a pitch deck for HMI Ventures who is an experienced venture builders and investing in and growing early technology."
                  }
                  presentationImage={HMI}
                />
              </div>
              <div className="section">
                <PresentationTemplate1
                  presentationTitle={"Assan"}
                  presentationDescription={
                    "It’s not just what you say that matters, it’s how you say it. A pitch deck design was developed and presented by our professionals for Asaan Tax demonstrating why it is the ultimate choice when it comes to easy tax filing, planning and refunds."
                  }
                  presentationImage={asaan}
                />
              </div>
            </motion.div>
          );
        }}
      />
    </>
  );
}

export default PresentationDesign;
