import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Career from "./Components/Career/Career";
import "antd/dist/antd.min.css";
import Footers from "./Components/Footer/Footers";
import WhatWeDo from "./Components/WhatWeDo/WhatWeDo";
import About from "./Components/About/About";
import vertical from "./Images/cubicVertical.svg";
import verticalRed from "./Images/cubicVertical_red.svg";
import square from "./Images/cubicLogo.png";
// fonts
import "./Font/Axiforma-Black.ttf";
import "./Font/Axiforma-BlackItalic.ttf";
import "./Font/Axiforma-Bold.ttf";
import "./Font/Axiforma-BookItalic.ttf";
import "./Font/Axiforma-Book.ttf";
import "./Font/Axiforma-ExtraBold.ttf";
import "./Font/Axiforma-ExtraBoldItalic.ttf";
import "./Font/Axiforma-Heavy.ttf";
import "./Font/Axiforma-Light.ttf";
import "./Font/Axiforma-Medium.ttf";
import "./Font/Axiforma-Regular.ttf";
import "./Font/Axiforma-SemiBold.ttf";
import "./Font/Axiforma-Thin.ttf";
import Navbar from "./Components/NavBar/Navbar";
import BrandDesign from "./Components/WhatWeDo/BrandDesign/BrandDesign";
import { useDispatch } from "react-redux";
import { setMenuColor, setNavLogo } from "./Redux/actions/NavRefAction";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import AppDesign from "./Components/WhatWeDo/AppDesign/AppDesign";
import WebDesign from "./Components/WhatWeDo/WebDesign/WebDesign";
import PresentationDesign from "./Components/WhatWeDo/PresentationDesign/PresentationDesign";
import CaseStudies from "./Components/CaseStudy/CaseStudies";
import CaseStudy from "./Components/CaseStudy/CaseStudy";

function App() {
  const dispatch = useDispatch();
  const navRef = useRef();
  // const navMenu = useRef();
  const [navbarLogo, setNavbarLogo] = useState(`${vertical}`);
  const [menuIconColor, setMenuIconColor] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("track intersection==", entry);

        if (entry.isIntersecting) {
          //do your actions here
          // navRef.current = square;
          dispatch(setNavLogo(`${verticalRed}`));
          // setNavbarLogo(`${square}`)
          // setMenuIconColor(true);
          console.log("found Div for nav!", navRef.current);
        } else {
          dispatch(setNavLogo(`${vertical}`));
          // setNavbarLogo(`${vertical}`)
          // setMenuIconColor(false);
          // navRef.current = vertical;
          console.log("remove!", navRef.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (navRef.current) {
      observer.observe(navRef.current);
    }

    // const observer2 = new IntersectionObserver(
    //   ([entry]) => {
    //     console.log(entry);

    //     if (entry.isIntersecting) {
    //       dispatch(setMenuColor(true));
    //       console.log('found Div for nav!', navRef.current)
    //     }else{
    //       dispatch(setMenuColor(false));
    //       console.log('remove!',navRef.current)
    //     }
    //   },
    //   {
    //     root: null,
    //     rootMargin: "0px",
    //     threshold: 0.1
    //   }
    // );
    // if (navMenu.current) {
    //   observer2.observe(navMenu.current);
    // }
  }, [navRef]);
  return (
    <>
      <Navbar navbarLogo={navbarLogo} menuIconColor={menuIconColor} />
      <Routes>
        <Route path="/" element={<Home navRef={navRef} />} />
        <Route path="career" element={<Career />} />
        <Route path="about_us" element={<About navRef={navRef} />} />
        <Route path="contact" element={<Footers />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/what_we_do" element={<WhatWeDo />} />
        <Route
          path="/what_we_do/digital_media_branding"
          element={<BrandDesign/>}
        />
        <Route path="/what_we_do/app_design" element={<AppDesign />} />
        <Route path="/what_we_do/web_design" element={<WebDesign />} />
        <Route
          path="/what_we_do/presentation_pitch_deck_design"
          element={<PresentationDesign />}
        />
        {/* </Route> */}
        <Route path="/case_studies" element={<CaseStudies />}></Route>
        <Route path="/case_studies/:caseId" element={<CaseStudy />} />
        <Route path="*" element={"Not Found"} />
      </Routes>
    </>
  );
}

export default App;
