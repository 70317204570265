import { Carousel, Col, Divider, Row, Skeleton } from "antd";
import React from "react";
import "./CaseStudies.css";
import { Avatar, List, Space } from "antd";
import navroz from "../../Images/Navroz.jpeg";
import { BsArrowRight } from "react-icons/bs";
import { Link, Outlet } from "react-router-dom";
import { caseStudyData } from "./CaseStudiesData";
import Footers from "../Footer/Footers";
import { UserOutlined } from "@ant-design/icons";
function CaseStudies() {
  const data = Array.from({
    length: 3,
  }).map((_, i) => ({
    id: i,
    href: "https://ant.design",
    title: `ant design part ${i}`,
    avatar: "https://joeschmoe.io/api/v1/random",
    description:
      "Ant Design, a design language for background applications, is refined by Ant UED Team.",
    content:
      "We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.",
  }));
  const IconText = ({ icon, text }) => (
    <Space>
      {React.createElement(icon)}
      {text}
    </Space>
  );
  return (
    <>
      <div className="caseStudiesDiv">
        <div className="caseStudiesSubDiv">
          <Row justify="center">
            <Col xs={22} sm={22} md={13} lg={13} xl={13}>
              <div className="caseStudiesMainTitle">Our Case Studies</div>
            </Col>
            <Col xs={20} sm={22} md={16} lg={18} xl={18} style={{marginBottom:"5rem"}}>
              <List
                itemLayout="vertical"
                size="large"
                //   pagination={{
                //     onChange: (page) => {
                //       console.log(page);
                //     },
                //     pageSize: 3,
                //   }}
                dataSource={caseStudyData}
                renderItem={(item, index) => (
                  <List.Item key={item.id} onClick={() => console.log(item)}>
                    <div className="caseStudiesListDiv">
                      <div className="caseStudiesContentDiv">
                        <div className="caseStudiesTitle">
                          <Avatar icon={<UserOutlined />} />
                          &nbsp;
                          <div
                            style={{ width: "fit-content" }}
                            dangerouslySetInnerHTML={{ __html: item.title }}
                          />
                        </div>
                        <div className="caseStudiesContentAndImgDiv">
                          <div style={{ width: "100%" }}>
                            <div
                              className="caseStudiesContent"
                              dangerouslySetInnerHTML={{ __html: item.data }}
                            />
                            <Link
                              to={`/case_studies/${item.id}`}
                              state={{ data: item }}
                            >
                              <span className="caseStudiesReadMore">
                                Read more &nbsp; <BsArrowRight size={20} />
                              </span>
                            </Link>
                          </div>
                          <div className="caseStudiesBannerImg">
                            {/* <Skeleton.Image active={true} /> */}
                            {item.image === "" ? (
                              <Skeleton.Image active={true} />
                            ) : (
                              <img
                                src={item.image}
                                alt="Case Study Pic"
                                height="90%"
                                width="90%"
                                style={{ borderRadius: "5px" }}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <Footers />
        </div>
      </div>
    </>
  );
}

export default CaseStudies;
