import { Carousel, Col, Divider, Row, Skeleton } from "antd";
import React from "react";
import { useLocation } from "react-router-dom";
import Footers from "../Footer/Footers";
import "./CaseStudies.css";
import ParticleBackground from 'react-particle-backgrounds'
function CaseStudy() {
  const location = useLocation();
  console.log("location:", location);
  const settings = {
    canvas: {
      canvasFillSpace: true,
      width: 200,
      height: 200,
      useBouncyWalls: true
    },
    particle: {
      particleCount: 60,
      color: 'red',
    //   color: '#d68c38',
      minSize: 2,
      maxSize: 5
    },
    velocity: {
      directionAngle: 0,
      directionAngleVariance: 30,
      minSpeed: 1,
      maxSpeed: 1
    },
    opacity: {
      minOpacity: 0,
      maxOpacity: 0.5,
      opacityTransitionTime: 5000
    }
  }
  return (
    <div className="caseStudiesDiv">
      <div className="caseStudiesSubDiv">
        <Row justify="center">
          {/* <Col xs={16} sm={22} md={20} lg={20} xl={20} xxl={22}> */}
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
          <div style={{position:"relative"}}>
          <div
              className="caseStudyMainTitle"
              dangerouslySetInnerHTML={{ __html: location.state.data.title }}
            />
            <div
              className="caseStudyParticleDiv"
            //   dangerouslySetInnerHTML={{ __html: location.state.data.title }}
            >
            <ParticleBackground settings={settings} />
            </div>
            </div>
          </Col>
          <Col xs={20} sm={22} md={16} lg={14} xl={14} xxl={20}>
            <div
              className="caseStudyMainContent"
              dangerouslySetInnerHTML={{ __html: location.state.data.data }}
            />
          </Col>
          <Col xs={16} sm={22} md={20} lg={20} xl={20} xxl={22}>
            <Divider> End</Divider>
          </Col>
        </Row>
        <div style={{marginTop:"4rem"}}>
        <Footers/>
        </div>
      </div>
    </div>
  );
}

export default CaseStudy;
