import { NavRefType } from "../constants/NavRefType";

export const setNavLogo = (response) =>{
    return {
        type : NavRefType.GET_NAV_LOGO ,
        payload: response   
    };
};
export const setMenuColor = (response) =>{
    return {
        type : NavRefType.GET_MENU_COLOR ,
        payload: response   
    };
};

export const sliderOpen = (response) =>{
    return {
        type : NavRefType.OPEN_SLIDER ,
        payload: response   
    };
};

export const categoryOpen = (response) =>{
    return {
        type : NavRefType.OPEN_WHAT_WE_DO ,
        payload: response   
    };
};