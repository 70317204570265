import React, { useEffect, useRef } from "react";
import "./BrandTemplate1.css";
import { Col, Image, Row } from "antd";
import { setMenuColor, setNavLogo } from "../../../Redux/actions/NavRefAction";
import { useDispatch } from "react-redux";
import verticalBlack from "../../../Images/cubicVertical_black.png";
import { motion } from "framer-motion";

function BrandTemplate1({ brandTitle, brandDescription, brandImage }) {
  const dispatch = useDispatch();
  const navRef = useRef();
  const navMenu = useRef("black");
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log("track intersection==", entry);

        if (entry.isIntersecting) {
          dispatch(setNavLogo(`${verticalBlack}`));
          console.log("found Div for nav!", navRef.current);
        }
        // else {
        //   dispatch(setNavLogo(`${vertical}`));
        //   console.log("remove!", navRef.current);
        // }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (navRef.current) {
      observer.observe(navRef.current);
    }
  }, [navRef]);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       if (entry.isIntersecting) {
  //         //do your actions here
  //         var nav = (navMenu.current = "white");
  //         dispatch(setMenuColor(nav));
  //         // dispatch(setNavLogo(verticalRed));
  //         console.log("found Div for nav!", navMenu.current);
  //       } else {
  //         // dispatch(setNavLogo(vertical));
  //         // navMenu.current = vertical;
  //         var nav = (navMenu.current = "black");
  //         dispatch(setMenuColor(nav));
  //         console.log("remove!", navMenu.current);
  //       }
  //     },
  //     {
  //       root: null,
  //       rootMargin: "0px",
  //       threshold: 0.1,
  //     }
  //   );
  //   if (navMenu.current) {
  //     observer.observe(navMenu.current);
  //   }
  // }, [navMenu]);

  useEffect(() => {
    dispatch(setMenuColor("white"));

    return () => {
      dispatch(setMenuColor("black"));
    };
  }, []);

  return (
    <div
      className="brandMainDiv"
      ref={navRef}
    >
      {/* <Row>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="brandCol1">
            <div className="subContentDiv3 brandTextAlignment">
              <h2 className="mainHeadings">{brandTitle}</h2>
              <div className="dnaSubTitle mainDescription">
              {brandDescription}
              </div>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={14} lg={14} xl={14}>
          <div className="brandCol2 bgColor">
            <div className="brandImageDiv">
              <img src={brandImage} alt="brands" height="100%" width="100%" />
            </div>
          </div>
        </Col>
      </Row> */}
      <Row justify="center" style={{ width: "100%" }}>
        <Col xs={24} sm={24} md={10} lg={10} xl={10}>
          <div className="brandCol1">
            <div className="subContentDiv3 brandTextAlignment">
              <h2 className="mainHeadings" style={{ color: "white" }}>
                {brandTitle}
              </h2>
              <div className="dnaSubTitle mainDescription">
                {brandDescription}
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={14}
          lg={14}
          xl={14}
          style={{ backgroundColor: "" }}
        >
          <div className="brandInnerDiv">
            <div className="threedDiv">
              {/* <img src={brandImage} alt="brands" height="100%" width="100%" /> */}
              <Image
                // width={1024}
                src={brandImage}
              />
            </div>
            {/* <div className="revealed-div">ABBAS</div> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default BrandTemplate1;
