import navroz from "../../Images/Navroz.jpeg";
import event1 from "../../Images/event1.PNG";
import event2 from "../../Images/event2.jpg";
import event3 from "../../Images/event3.jpg";
import event4 from "../../Images/event4.jpg";
import "../CaseStudy/CaseStudies.css"
import uiUxImage from "../../Images/ui_ux_caseStudy.webp"
import digitalStartup from "../../Images/digitalStartup.webp"
export const caseStudyData = [
  {
    id: "1",
    title: `<b>The ever-changing trends in UI-UX design</b>`,
    image: uiUxImage,
    data: `<p>We are living in technology driven era where the digital trends are changing within the blink of an eye. 
            The year 2023 is around the corner and shaping up to be a big one for user interface (UI) and user 
            experience (UX) design. The upsurge of technology has made it more important than ever for a product's 
            UX to be intuitive, easy-to-use, and visually appealing. 
            <br/> 
            <p>Moving forward, we'll see a shift in what we consider user experience and user interface. 
            This is because of the rise of artificial intelligence (AI) and the ability to communicate between 
            devices and computers in a way that was previously not possible. With implementation of AI tools in 
            UI-UX design, we can create interactions that are more natural than ever before. According to a conducted 
            study, if AI is used as a functional tool in design, it will optimize and facilitate processes and tasks such as testing, 
            research and planning. Are you looking for personalized latest UI/UX design, 
            reach us out at <a href="/" style="color:#fd0202; textDecoration:none;">www.cubesanddots.com.</a> </p>
            <h2 style="color:#fd0202"><b>Spotlight on UI/UX Trends</b></h2> 
            <br/>
            Let’s dive into the top trends that decides the future of UI-UX design:
            <ol style="padding-left:2rem">
            <li class="orderList">A focus on user experience over technology</li>
            <li>An increased emphasis on data and analytics</li>
            <li>A move away from skeuomorphism to flat design</li>
            <li>More focus on emojis as a way to communicate with users</li>
            <li>Greater use of machine learning</li>
            <li>Voice interface for accelerated user experience</li>
            <li>Mobile first approach</li>
            </ol>
            <h2 style="color:#fd0202"><b>User Experience over Technology</b></h2>
            <p>More immersive user experience design (UI/UX) that uses the virtual reality, augmented 
            reality, and mixed reality technologies are creating more immersive user experience. 
            This will allow users to interact with products in new ways and have their physical 
            surroundings change based on their actions.</p>
            <p>User experience designers have always been concerned with how their products look and 
            feel. But with the rise of mobile devices and tablets, there's a new focus on making sure 
            that what you're seeing on your screen is clear and easy to navigate.</p>
            <p>No matter what type of device people use, desktop computer, laptop, tablet, and 
            smartphone, they all need a smooth user experience that helps them complete tasks 
            quickly and easily.</p>
            <h2 style="color:#fd0202"><b>Emphasized Data and Analytics</b></h2>
            <p>With each passing day, the UI-UX world is changing and from a user perspective, the 
            changes are subtle yet significant. The focus has shifted from designing for aesthetics 
            to designing for insights and data. Rather than creating beautiful interface for increased 
            interactions, the best UI-UX design focuses on resolving user issues and making informed 
            decisions by analyzing the collected data.</p>
            <p>Users are globally connected and they are more concerned about how they feel about 
            certain product which arise the fact that UI-UX designers need to be aware of what’s 
            happening around them in terms of user behavior patterns, preferences and needs. </p>
            <h2 style="color:#fd0202"><b>Flat Design over Skeuomorphism </b></h2>
            <p>Skeuomorphism is the use of visual elements that mimic real-world objects, like buttons and 
            paper while flat design uses flat shapes and minimalistic metaphors. Using flat design in UI-UX 
            allows users to apply their imagination while looking at something without using any visual 
            aspects of a real-world object. From a user experience perspective, flat design makes the content 
            look more professional and clean, while skeuomorphism makes it look old-fashioned and outdated. </p>
            <h2 style="color:#fd0202"><b>Emojis are Communicating like No One</b></h2>
            <p>One of the most important trends in UI-UX design is the use of emojis. According to a report 
            by research firm Global Web Index, users spend an average of 15 minutes per day looking 
            at emojis on their phones, which means that they spend one-third of their time looking at emojis. 
            Furthermore, 55% of all messages sent via messaging apps are sent as emojis.</p>
            <p>When it comes to introducing more and expressive emojis, the best user experience demands not 
            only an object or shape but they look for something that will help them communicate their emotions 
            and needs. </p>
            <h2 style="color:#fd0202"><b>Greater Use of Machine Learning</b></h2>
            <p>The continued advancement and implementation of machine learning will allow computers to improve 
            over time by learning from existing data set. That means that UI-UX designers can expect to see more 
            sophisticated artificial intelligence systems used in new products over time. This promises a shift 
            away from static designs toward ones that are dynamic and responsive and that's something we can get 
            excited about!</p>
            <h2 style="color:#fd0202"><b>Voice interface for accelerated user experience</b></h2>
            <p>The rise of voice interfaces continues to make it easier to interact with smartwatches, thermostats 
            and other devices. Their application in UI-UX design allows users to complete tasks without having their 
            hands on their device at all times. This results in less strain on users' eyes and wrists while they're 
            using these devices, while also reducing the need for constant hand-gestures and promising all time 
            enhanced user experience.  </p>
            <h2 style="color:#fd0202"><b>Mobile first approach</b></h2>
            <p>The mobile first approach is a design strategy that aims to create the best possible user experience for 
            a website and its visitors on mobile devices. The seamless UI-UX design incorporates the mobile first 
            approach by just not enabling it for smartphones and tablets but it creates the best experience for 
            users no matter opened on which device and environment. Since the users with smartphones are increasing 
            day by day, if opted for mobile first approach in UI design, it promises the enhanced user interactions 
            and accelerated users engagement by means of easy navigation of any website on mobile devices. </p>
            <p>I anticipate the year 2023 will be an interesting and fun ride for UI-UX design with ever rising 
            competition and implementation of AI design tools. Keeping these trends in mind will definitely enable 
            the designers create apps and websites with elevated user experience. Feel free to leave any feedback 
            and connect with our UI design geeks for custom UI-UX design.</p>
            `,
    iconImage: "",
    author: "Jessica Martinez",
  },
  {
    id: "2",
    title: " <b>Establishment of Startups Companies and Digital Marketing</b>",
    image: digitalStartup,
    data: `<p>Are you looking to get your startup off the ground? Digital marketing is the way to go. 
    Digital marketing is a powerful tool for any entrepreneur, but it's the backbone for startups. After 
    COVID 19, the ecommerce industry witnessed an upsurge where people tend to avail services through Digital 
    media rather than rushing to physical stores and it is enough evident that ecommerce is the future of 
    business world. </p>
    <p>Amidst the saturated and highly competitive market, the best way to connect to new audience and 
    reinforce ties with existing customers is effective use of digital marketing. The question in hand is 
    which platforms can be used as tool for digital marketing. Internet has connected the world like never 
    before and the social media platforms are the median to connect them over the globe.</p>
    <p>The social media platforms include social network (Facebook, LinkedIn, Instagram), micro blogs 
    (Twitter, Pinterest, Tumblr), and videos (YouTube and Vimeo) where users are connected with each others 
    and service providers. </p>
    <p>As with any other form of marketing, the effectiveness of digital marketing depends on how well you 
    execute your strategy. With new technology popping up every day and new ways to market your product or 
    service becoming available at all times, it can be difficult to keep track of everything that is going 
    on in this ever-changing world. But you don't have to worry about that because we're here to help! Are 
    you ready to get your startup the right branding and marketing it deserves? Get in touch with us here 
    <a href="/" style="color:#fd0202; textDecoration:none;">www.cubesanddots.com.</a> </p>
    <p>Let’s get insight on how digital marketing influence the businesses and especially startups. Among 
    the many impacts, few are being highlighted here. </p>
    <ol>
                <li>Increased brand awareness</li>
                <li>Social media engagement</li>
                <li>Better customer relationships</li>
                <li>Higher sales and revenue</li>
                <li>Better search engine ranking</li>
                <li>Optimum marketing costs</li>
    </ol>
            <h2 style="color:#fd0202"><b>Increased Brand Awareness</b></h2>
            <p>Digital marketing is one of the best ways to increase brand awareness. The use of social 
            media, email marketing, search engine optimization (SEO), display ads and much more that can 
            help you reach more people than ever before. This allows you to build an audience of potential 
            customers who are interested in your product or service.</p>
            <p>It also enables the startups to connect with other businesses who have similar interests as 
            yours so that they can help each other grow their business as well.</p>
            <h2 style="color:#fd0202"><b>Social Media Engagement</b></h2>
            <p>For the establishment of your startups, it's important to engage with your followers and customers on social 
            media because you are what they think of you. The power of social media has made it a big deal to 
            actively engage with your potential customers to reach your product to the right users. One of the 
            biggest trends in digital marketing right now is influencer marketing. This type of marketing involves 
            partnering with individuals who have a large following (i.e., influencers) and using their 
            influence over users to drive awareness and sales for your product or service </p>
            <h2 style="color:#fd0202"><b>Better Customer Relationships</b></h2>
            <p>Branding of the startups requires to be obsessed with the idea of building a customer 
            relationship. It is vital to know what the customer wants, how they feel and even who they are. 
            We need to know how we can help them, because that's the only way we can bring right solution in 
            the form of right service/product to them. </p>
            <p>The strategic use of digital marketing helps the customer convey their expectations and let 
            startups connect with them on mutual grounds. Through the use of data tools like Facebook Meta, 
            Google Analytics, we can target an audience by region, age, gender, and interests that are 
            relevant to your business. </p>
            <h2 style="color:#fd0202"><b>Higher Sales and Revenue</b></h2>
            <p>The higher sales and return on investment are the key parameters to identify the potential of 
            startups to get established and stand on their own. The effective marketing strategy is all you 
            need to reach out to potential customers. It offers a reliable way to increase brand awareness, 
            drive traffic, and convert users into potential customers.</p>
            <h2 style="color:#fd0202"><b>Better Search Engine Ranking</b></h2>
            <p>Digital marketing has proved its usability to get your business noticed by potential 
            customers who are searching online for the services or products you offer. The effective 
            choice of right keywords helps the marketing do its wonders while doing search engine 
            optimization (SEO) of your website or online store. </p>
            <p>Search engine algorithms are constantly evolving and changing, which means that a small 
            change in your website's content or structure can have a major impact on your organic 
            ranking and conversions.  </p>
            <h2 style="color:#fd0202"><b>Optimum Marketing Costs</b></h2>
            <p>If we want to reach larger audience and compete with other business without spending as 
            much as it would take traditionally (TV commercials, print advertising) then digital marketing 
            is the answer. Let’s be clear that digital marketing isn't just about paid ads but it is about 
            building your brand and growing your customer base with content that speaks directly to your 
            target audience.</p>
            <p>Connecting with your users on social, national, and spiritual grounds make them like you more 
            and advertise your product to their connections without you spending any penny. 
            All it takes is the right content on right media to reach right audience for your product/service</p>
            <p>If I conclude then it won’t be wrong to say that effective use of digital marketing and social 
            media gives you access to your customer’s mind and heart. In short, along with having an active 
            marketing plan the key to establish your startup is identifying the target audience, 
            understanding their needs, and then presenting your product/service through chosen media</p>
            <p>Let your startups get the right branding with us and find your solutions here <a href="/" style="color:#fd0202; textDecoration:none;">www.cubesanddots.com.</a></p>
            
    `,
    iconImage: "",
    author: "Michael Davis",
  },
  {
    id: "3",
    title:
      " <b>Mentor Health Lahore Chapter Launched in Digital Partnership with Cubes & Dots</b>",
    image: event1,
    data: `<p>“Listen from your heart, validate from your mind, and then keep moving” said by one of the attendees 
    in Launch Event of Mentor Health - Lahore Chapter that was organized on 8th December 2022 at Nine Tree 
    Hotel, Lahore. One cannot get those words out of their mind which actually touched their soul because 
    it made sense in every direction. Mentor Health with theme of Health Budget Management & Employee 
    Wellbeing launched its integrated health ecosystem to address the health budget and employee healthcare 
    concerns. </p>
    <br/>
    <div class="caseStudyDocImage"><img src=${event1} alt="Event Picture" class="docImage"/></div>
    <br/>
    <p>When it comes to human wellbeing, we need something that is not just technology laced but it requires to be 
    synced with the human behavior. Being the digital partner, Cubes & Dots arranged a fun filled media wall 
    interaction activity that gave an interesting start to the evening. The activity was hosted by 
    Ms. Bushra Khan (Journalist Public Relations, CEO at PR by BK) and co-hosted by Mr. Ahsan Khan 
    (Co-founder and CBDO at Cubes & Dots) with event’s PR Partner Famers, and Event Partner Linkers.</p>
    <br/>
    <div class="caseStudyDocImage"><img src=${event2} alt="Event Picture" class="docImage"/></div>
    <br/>
    <p>Leading to next step, an insightful speech on Mentor Health introduction was given by Dr. 
    Faiza Barkat (Lead Integration & Processes) where she demonstrated what is Mentor Health and 
    how it is working for the betterment of digital healthcare services for both individuals and corporates.</p>
    <p>The event was set forth with panel discussion on “Health Budget Management & Employee Wellbeing” which 
    was moderated by Ms. Huma Mazher (Chief Strategy Officer at Naqeebz). The panel discussion was carried by 
    well experienced and renowned corporate leaders to enlighten the challenges faced while maintaining health 
    budget and employee wellbeing. The panelists were Muhammad Gohar Shafique (CEO at Buzz Interactive and 
    Huddle Coworking), Mr. Faisal Sheikh (Chief HR Officer Fauji Foods Limited), Mr. Adeel Ahmed 
    (CFO Diamond Group), and Mr. Hamid Raza (CEO Neutro Pharma Pvt Limited). </p>
    <div class="caseStudyDocImage""><img src=${event3} alt="Event Picture" class="docImage"/></div>
    <br/>
    <p>From connecting with C-Level executives from different corporate sectors to developing an 
    understanding of how they are striving to maintain their employee wellbeing was an experience of a kind. 
    The event was hyped with an interactive session on “Boost Workplace Productivity & Stress Management” by 
    Ms. Mary Pervaiz (Psychology & Wellness Consultant). She managed to get everyone participated in the fun 
    yet prolific activities on how to manage your daily life stress and express your emotions at workplace 
    and in personal lives. The wellbeing starts from within yourself and only when you find a route to reach 
    your inner self, you touch the shores of calmness.</p>
    <p>Being surrounded by digital world, digital health facilities have been long ignored in Pakistan 
    because it takes a lot to trust and rely on health tech services. Keeping that in view, Co-founder & 
    COO of Mentor Health, Mr. Navroz Mansiya demonstrated the services of Mentor Health app for individuals 
    and the Corporate Portal where the companies can monitor and manage their employee healthcare budget 
    allocation.</p>
    <p>Time is the most precious asset and as a token of gratefulness, shields were awarded to all the 
    panelists, moderator, keynote speaker, and host who collectively made the event memorable one. 
    Last but not least, it will not be wrong to conclude that if we sit together at the healthcare concerns 
    and let everyone bring their perspective on how to bring a better change, it will be a lot easy to make 
    this society and planet a heaven. Have a good day!</p>
    <div class="caseStudyDocImage"><img src=${event4} alt="Event Picture" class="docImage"/></div>

    `,
    iconImage: "",
    author: "Jessica Martinez",
  },
];
{/* <div style="width:30rem; height:25rem;"><img src=${event2} alt="Event Picture" height="100%" width="100%"/></div> */}
