import React, { useEffect, useRef, useState } from "react";
import "./Navbar.css";
// import cubicLogo from "../../Images/cubicLogo.png";
import cubicLogo from "../../Images/cubicLogo.png";
import cubicVerticalLogo from "../../Images/cubicVertical.svg";
// import cubicVerticalLogo from "../../Images/cubicVertical.png";
import { IoClose, IoMenuSharp } from "react-icons/io5";
import CategoryDrawer from "../../SubComponents/CategoryDrawer/CategoryDrawer";
import { AnimatePresence, motion } from "framer-motion";
import Hamburger from "hamburger-react";
import { Col, Row } from "antd";
import { BsArrowRight, BsArrowLeft } from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { sliderOpen } from "../../Redux/actions/NavRefAction";

const items = [
  {
    key: "1",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    ),
  },
  {
    key: "2",
    label: (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href="https://www.antgroup.com"
      >
        1st menu item
      </a>
    ),
  },
];

const variants = {
  open: {
    x: 0,
    transition: { delay: 0.2, ease: "easeIn", duration: "0.7" },
  },
  closed: {
    x: 4000,
    opacity:0,
    transition: { delay: 0.5, type: "tween", ease: "easeOut", duration: "1" },
  },
  none: {
    x: 4000,
    // opacity:0,
  },
  exit: {
    opacity: 0,
    y: -200,
    scale: 0.8,
    transition: { delay: 0.5, type: "tween", ease: "easeOut", duration: "1" },
  },
  exit2: {
    opacity: 0,
    y: -600,
    scale: 0.8,
    transition: { delay: 0.5, type: "tween", ease: "easeOut", duration: "1" },
  },
  on: {
    opacity: 1,
  },
  off: {
    opacity: 0,
  },
};

function Navbar({ navbarLogo }) {
  // const myNav = document.getElementById('header')
  // const [navbar, setNavbar] = useState(false)
  // window.onscroll = function() {
  //   if(window.scrollY > 500){
  //     setNavbar(true);
  //     console.log("abbas");
  //   }else{
  //     setNavbar(false);
  //     console.log("raza");
  //   }
  // }
  const openSlider = useSelector((state) => state.navReducer.sliderState);
  const categoryOpen = useSelector((state) => state.navReducer.categoryState);
  const logo = useSelector((state) => state.navReducer.navLogoPayload);
  const menuColor = useSelector((state) => state.navReducer.menuColorPayload);
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);
  const [hover2, setHover2] = useState(false);
  const [hover3, setHover3] = useState(false);
  const [backHover, setBackHover] = useState(false);
  const [open, setOpen] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const dispatch = useDispatch();
  // function onTap(event, info) {
  //   console.log(info.point.x, info.point.y, event)
  // }

  useEffect(() => {
    console.log("In Nav===", navbarLogo);
  }, [navbarLogo, logo]);

  useEffect(() => {
    console.log("In Nav Menu===", menuColor);
  }, [menuColor]);

  useEffect(() => {
    // if(!openSlider){
    //   setOpen(openSlider);
    // }
    setOpen(openSlider);
    setIsVisible(categoryOpen);
    // console.log(openSlider, "slider===");
    // console.log(categoryOpen, "slider===2");
  }, [openSlider, categoryOpen]);

  const closeSlideFunc = () => {
    setOpen(false);
    dispatch(sliderOpen(false));
  };

  return (
    <>
      <nav className="navbarMain">
        <div className="navbarParent">
          <div className="navbarLogoDiv">
            <div className="logoNav2">
              <Link to="/">
                <img
                  // src={cubicVerticalLogo}
                  src={logo}
                  height="100%"
                  width="100%"
                  alt="Logo"
                />
              </Link>
            </div>
          </div>

          {/* <div className="navBarMenu2">
            <div className="navBarMenuList">What we do</div>
            <div className="navBarMenuList">Company</div>
            <div className="navBarMenuList">Contact</div>
            <div className="navBarMenuList">More</div>
          </div> */}

          <motion.div
            whileHover={{
              scale: 1.2,
              color: "var(--primary_color)",
              rotate: 360,
            }}
            whileTap={{ scale: 0.9, color: "grey" }}
            transition={{ duration: 0.5 }}
            className="menuBtn2"
            onClick={() => {
              setOpen(true);
              setIsVisible(false);
            }}
          >
            <IoMenuSharp
              size={30}
              className={
                menuColor == "black"
                  ? "navIcon"
                  : menuColor == "white"
                  ? "navIcon2"
                  : ""
              }
            />
          </motion.div>

          <div className="navBarMenu">
            {/* <div className="navBarMenuName">All Categories</div>
            <div className="navBarMenuName2"><MdExpandMore size={25}/></div>
            <div className="navBarMenuLine"></div> */}
            {/* <div className="menuBtn"><GiHamburgerMenu size={25}/></div> */}
            <CategoryDrawer />
          </div>
        </div>
      </nav>

      <motion.div
        variants={variants}
        animate={open === true ? "open" : open === false ? "closed" : "none"}
        className="slideMenu"
      >
        {isVisible == true ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "fit-content",
            }}
          >
            <motion.div
              onClick={() => {
                closeSlideFunc();
                // setIsVisible(false);
                // dispatch(categoryOpen(false));
              }}
              whileHover={{
                scale: 1.2,
                color: "var(--primary_color)",
                rotate: 360,
              }}
              whileTap={{ scale: 0.9, color: "grey" }}
              transition={{ duration: 0.5 }}
              className="menuBtn2"
            >
              <IoClose size={30} />
            </motion.div>
            {/* =====back button===== */}
            <motion.span
              className="menuBackBtn"
              onClick={() => {
                setIsVisible(false);
                dispatch(categoryOpen(false));
              }}
              whileHover={{ color: "var(--primary_color)" }}
              onHoverStart={() => setBackHover(true)}
              onHoverEnd={() => setBackHover(false)}
              whileTap={{ scale: 0.9, color: "black" }}
              transition={{ duration: 0.3, ease: "easeOut" }}
            >
              {backHover == true ? (
                <motion.span className="menuBackBtn">
                  <BsArrowLeft size={20} />
                </motion.span>
              ) : null}
              &nbsp;Back
            </motion.span>
          </div>
        ) : (
          <motion.div
            onClick={() => {
              closeSlideFunc();
              // setIsVisible(false);
              // dispatch(categoryOpen(false));
            }}
            whileHover={{
              scale: 1.2,
              color: "var(--primary_color)",
              rotate: 360,
            }}
            whileTap={{ scale: 0.9, color: "grey" }}
            transition={{ duration: 0.5 }}
            className="menuBtn2"
          >
            <IoClose size={30} />
          </motion.div>
        )}

        <AnimatePresence>
          {isVisible === false && (
            <motion.div
              variants={variants}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={"exit"}
              className="menuDiv"
            >
              <Row className="menuRow">
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <motion.div className="menuListDiv">
                    <motion.span
                      className="menuSpan"
                      animate={{
                        color: "black",
                        x: hover ? -10 : 0,
                      }}
                      whileHover={{ scale: 1.2, color: "var(--primary_color)" }}
                      onHoverStart={() => setHover(true)}
                      onHoverEnd={() => setHover(false)}
                      whileTap={{ scale: 0.9, color: "black" }}
                      transition={{ duration: 0.3, ease: "easeOut" }}
                      onClick={() => setIsVisible(true)}
                    >
                      What we do &nbsp;
                      {hover ? (
                        <motion.span
                          initial={{ x: -20 }}
                          animate={{ x: 0 }}
                          className="arrowSpan"
                        >
                          <BsArrowRight />
                        </motion.span>
                      ) : null}
                    </motion.span>
                  </motion.div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <motion.div className="menuListDiv">
                    <Link
                      to="/about_us"
                      className="link"
                      onClick={() => setOpen(false)}
                    >
                      <motion.span
                        animate={{
                          color: "black",
                          x: hover1 ? -10 : 0,
                        }}
                        whileHover={{
                          scale: 1.2,
                          color: "var(--primary_color)",
                        }}
                        onHoverStart={() => setHover1(true)}
                        onHoverEnd={() => setHover1(false)}
                        whileTap={{ scale: 0.9, color: "black" }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        className="menuSpan"
                      >
                        Company &nbsp;
                        {hover1 ? (
                          <motion.span
                            initial={{ x: -20 }}
                            animate={{ x: 0 }}
                            className="arrowSpan"
                          >
                            <BsArrowRight />
                          </motion.span>
                        ) : null}
                      </motion.span>
                    </Link>
                  </motion.div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <motion.div className="menuListDiv">
                    <Link
                      to="/contact"
                      className="link"
                      onClick={() => setOpen(false)}
                    >
                      <motion.span
                        animate={{
                          color: "black",
                          x: hover2 ? -10 : 0,
                        }}
                        whileHover={{
                          scale: 1.2,
                          color: "var(--primary_color)",
                        }}
                        onHoverStart={() => setHover2(true)}
                        onHoverEnd={() => setHover2(false)}
                        whileTap={{ scale: 0.9, color: "black" }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        className="menuSpan"
                      >
                        Contact &nbsp;
                        {hover2 ? (
                          <motion.span
                            initial={{ x: -20 }}
                            animate={{ x: 0 }}
                            className="arrowSpan"
                          >
                            <BsArrowRight />
                          </motion.span>
                        ) : null}
                      </motion.span>
                    </Link>
                  </motion.div>
                </Col>
                <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                  <motion.div className="menuListDiv">
                    <Link
                      to="/career"
                      className="link"
                      onClick={() => setOpen(false)}
                    >
                      <motion.span
                        animate={{
                          color: "black",
                          x: hover3 ? -10 : 0,
                        }}
                        whileHover={{
                          scale: 1.2,
                          color: "var(--primary_color)",
                        }}
                        onHoverStart={() => setHover3(true)}
                        onHoverEnd={() => setHover3(false)}
                        whileTap={{ scale: 0.9, color: "black" }}
                        transition={{ duration: 0.3, ease: "easeOut" }}
                        className="menuSpan"
                      >
                        Career &nbsp;
                        {hover3 ? (
                          <motion.span
                            initial={{ x: -20 }}
                            animate={{ x: 0 }}
                            className="arrowSpan"
                          >
                            <BsArrowRight />
                          </motion.span>
                        ) : null}
                      </motion.span>
                    </Link>
                  </motion.div>
                </Col>
              </Row>
            </motion.div>
          )}
        </AnimatePresence>

        <AnimatePresence>
          {isVisible === true && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { delay: 1, ease: "easeIn", duration: "1" },
              }}
              // transition= {{delay: 0.2, ease: "easeIn", duration: "1" }}
              // exit={{ opacity: 0, y:-600, scale: 0.8 }}
              exit={"exit2"}
              className="menuDiv"
            >
              <Row gutter={[32, 48]} className="menuRow2" justify="center">
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <div className="menuListDiv2">
                    <Link
                      to="/what_we_do/app_design"
                      className="navLink"
                      onClick={() => closeSlideFunc()}
                    >
                      <motion.span
                        variants={variants}
                        animate={
                          isVisible == true
                            ? "on"
                            : isVisible === false
                            ? "off"
                            : "none"
                        }
                        className="menuSpan"
                      >
                        App Design & Development
                      </motion.span>
                    </Link>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={7}>
                  <div className="menuListDiv2">
                    <Link
                      to="/what_we_do/web_design"
                      className="navLink"
                      onClick={() => closeSlideFunc()}
                    >
                      <span className="menuSpan">Web Design & Development</span>
                    </Link>
                  </div>
                </Col>
                {/* <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <div className="menuListDiv2">
                    <Link
                      to="/what_we_do/digital_media_branding"
                      className="navLink"
                      onClick={() => closeSlideFunc()}
                    >
                      <span className="menuSpan">Digital Media & Branding </span>
                    </Link>
                  </div>
                </Col> */}
                {/* =================================== */}
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <div className="menuListDiv2">
                    <Link
                      to="/what_we_do/digital_media_branding"
                      className="navLink"
                      onClick={() => closeSlideFunc()}
                    >
                      <span className="menuSpan">Digital Marketing & Branding</span>
                    </Link>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <div className="menuListDiv2">
                    <Link
                      to="/what_we_do/presentation_pitch_deck_design"
                      className="navLink"
                      onClick={() => closeSlideFunc()}
                    >
                      <span className="menuSpan lineHeight">Presentations & Pitch Deck Design </span>
                    </Link>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} lg={12} xl={7}>
                  <div className="menuListDiv2">
                    <Link
                      to="#"
                      className="navLink"
                      onClick={() => closeSlideFunc()}
                    >
                      <span className="menuSpan">
                       Content Development
                      </span>
                    </Link>
                  </div>
                </Col>
                {/* =================================== */}                
                <Col xs={24} sm={24} md={12} lg={12} xl={8}>
                  <div className="menuListDiv2">
                    <Link
                      to="#"
                      className="navLink"
                      onClick={() => closeSlideFunc()}
                    >
                      <span
                        className="menuSpan"
                        // style={{ lineHeight: "1.2rem" }}
                      >
                        Motion Animation & Design{" "}
                      </span>
                    </Link>
                  </div>
                </Col>
                {/* =================================== */}
              </Row>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </>
  );
}

export default Navbar;
