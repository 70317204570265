import React, { useState } from "react";
import "./CategoryDrawer.css";
import { Sling as Hamburger } from "hamburger-react";
import { Drawer, Space } from "antd";
import { Link } from "react-router-dom";
import cubicLogo from "../../Images/cubicLogo.png";
import { motion } from "framer-motion";

const variants = {
  open: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.2, type: "spring", stiffness: 100 },
  },
  closed: { opacity: 0, x: "-20%" },
};
const variants2 = {
  open: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.3, type: "spring", stiffness: 100 },
  },
  closed: { opacity: 0, x: "-20%" },
};
const variants3 = {
  open: {
    opacity: 1,
    x: 0,
    transition: { delay: 0.4, type: "spring", stiffness: 100 },
  },
  closed: { opacity: 0, x: "-20%" },
};

function CategoryDrawer() {
  const [isOpen, setOpen] = useState(false);
  const menuList = [
    { id: 1, listname: "What we do", link: "/what_we_do", variants:variants },
    { id: 2, listname: "Company", link: "/", variants:variants2 },
    { id: 3, listname: "Contact", link: "/contact", variants:variants3 },
  ];
  const menuList2 = [
    { id: 1, name: "Careers", link: "/career" },
    { id: 2, name: "Linkedin", link: "/" },
    { id: 3, name: "Dribble", link: "/" },
    { id: 4, name: "Instagram", link: "/" },
    { id: 5, name: "Twitter", link: "/" },
    { id: 6, name: "Privacy Policy", link: "/" },
  ];

  return (
    <div>
      <div className="menuBtn">
        <Hamburger
          toggled={isOpen}
          distance="sm"
          rounded
          label="show menu"
          toggle={setOpen}
          size={25}
        />
      </div>
      <Drawer
        // title="Basic Drawer"
        placement="right"
        className="my-drawer"
        closable={false}
        // onClose={onClose}
        open={isOpen}
      >
        <div className="insideMenu">
          <div className="menuLogo">
            {/* <img src={cubicLogo} height="100%" width="100%" alt="Logo" /> */}
          </div>
          <Hamburger
            toggled={isOpen}
            distance="sm"
            rounded
            label="show menu"
            toggle={setOpen}
            size={25}
          />
        </div>
        <motion.div
          // animate={isOpen ? "open" : "closed"}
          // variants={variants}
          className="menu1"
        >
          {menuList.map((data, index) => (
            <Link to={data.link} style={{ color: "black" }}>
              <motion.div
                animate={isOpen ? "open" : "closed"}
                variants={data.variants}
                className="menuList"
                key={index}
              >
                {data.listname}
              </motion.div>
            </Link>
          ))}
        </motion.div>
        <div className="menu2">
          {menuList2.map((data, index) => (
            <Link to={data.link} style={{ color: "silver" }}>
              <div key={index} className="menu2Text">
                {data.name}
              </div>
            </Link>
          ))}
        </div>
      </Drawer>
    </div>
  );
}

export default CategoryDrawer;
