import React from 'react'
import "../../Components/Career/Career.css"
import {BsArrowRight} from "react-icons/bs"
import { Link } from 'react-router-dom'
function BioDataList({icon, titleName, list}) {
  return (
    <div className='listDiv'>
        <div className='mainHead'>
          {icon} &nbsp; {titleName}
        </div>
        {list.map((data, index)=>(
        <Link to={data.link} style={{textDecoration:"none", color:"black"}}>
        <div className='listing' key={index}>
            <div className='listTitle'>{data.title} &nbsp; <BsArrowRight style={{color:"#fd0202"}}/></div>
            <div className='listSubTitle'>{data.subTitle}</div>
        </div>
        </Link>
        ))}
    </div>
  )
}

export default BioDataList