import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import "../About/About.css";
import Navbar from "../NavBar/Navbar";
import BioDataList from "../../SubComponents/BioDataList/BioDataList";
import { SiExpertsexchange } from "react-icons/si";
// import { Link } from "react-router-dom";
// import { BsArrowRight } from "react-icons/bs";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { setNavLogo } from "../../Redux/actions/NavRefAction";
import vertical from "../../Images/cubicVertical.svg";
import verticalRed from "../../Images/cubicVertical_red.svg";
import square from "../../Images/cubicLogo.png";
function About({ navRef }) {
  const dispatch = useDispatch();
  const list1 = [
    {
      id: 1,
      title: "Experience Director",
      subTitle: "West Coast / Full Time",
      link: "#",
    },
    {
      id: 2,
      title: "Lead Experience Designer",
      subTitle: "West Coast / Freelance",
      link: "#",
    },
    {
      id: 3,
      title: "Senior Experience Designer",
      subTitle: "West Coast / Full Time",
      link: "#",
    },
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        console.log(entry);

        if (entry.isIntersecting) {
          //do your actions here
          dispatch(setNavLogo(`${verticalRed}`));
          console.log("found Div for nav!", navRef.current);
        } else {
          // navRef.current = vertical;
          dispatch(setNavLogo(`${vertical}`));
          console.log("remove!", navRef.current);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (navRef.current) {
      observer.observe(navRef.current);
    }
  }, [navRef]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.2 }}
      className="aboutDiv"
    >
      {/* <Navbar /> */}
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="aboutBanner" ref={navRef}>
            <div className="image">
              {/* <img src={aboutImage} alt="about" height="100%" width="100%" /> */}
            </div>
            <div className="aboutHeading"></div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <div className="contentDiv">
            <div className="content">
              <h2 className="mainHeadings">
                <div className="contentHead">
                  About Us
                </div>
              </h2>
              <div className="contentPara mainDescription">
                Finding a way to reach your potential audiences online can be
                elusive; However, Cubes and Dot helps increase engagement and
                drive business results for your company through our Strategies,
                Design, Content, and Planning.
              </div>
              <div className="contentPara mainDescription">
                What distinguishes us is that we always believe that your
                business idea is a dot that we can turn into a cube through our
                personalized approach, intuitive knowledge, and provocative
                ideas. bridging the marketing gap and transforming your business
                into an ideal brand for your target customers.
              </div>
              {/* <BioDataList
                icon={<SiExpertsexchange />}
                titleName={"Experience"}
                list={list1}
              /> */}
            </div>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}

export default About;
